import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment';

import {
  // Icon,
  Message,
  // Segment,
  // Accordion,
  // Menu,
  // Dropdown,
  // Sidebar,
  // Button,
  // Header,
  // Dimmer,
  Loader,
  // Form,
  // TextArea,
  // Confirm,
  Card,
  Label,
  // List,
  // Modal,
  // Input,
} from 'semantic-ui-react'

class VUICard extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    status: PropTypes.object.isRequired,
    newOne: PropTypes.bool.isRequired,
    statusLoader: PropTypes.bool.isRequired,
  }

  getStatusInfo = () => {
    let statusName, statusColor;

    switch(this.props.status.status) {
      case 'READY':
        statusName = 'READY';
        statusColor = 'green';
        break;
      case 'READY_BASIC_TESTING':
        statusName = 'READY BASIC TESTING';
        statusColor = 'olive';
        break;
      case 'BUILDING':
        statusName = 'BUILDING';
        statusColor = 'yellow';
        break;
      case 'FAILED':
        statusName = 'FAILED';
        statusColor = 'red';
        break;
      case 'NOT_BUILT':
        statusName = 'NOT BUILT';
        statusColor = 'grey';
        break;
      default:
        if (this.props.newOne) {
          statusName = 'NEW';
          statusColor = 'teal';
        } else {
          statusName = 'OTHER';
          statusColor = 'black';
        }
    }

    return { statusName, statusColor };
  }

  render = () => {
    const { statusLoader } = this.props;
    const { statusName, statusColor } = this.getStatusInfo();

    return (
      <Card fluid color={statusColor}>
        <Card.Content>
          <Card.Header>
            {this.props.name}
          </Card.Header>
          <Card.Meta>
            <b>{moment().from(this.props.status.lastUpdatedDate, true)} &nbsp;</b>
            <i>(updated: {moment().from(this.props.status.createdDate, true)})</i>
          </Card.Meta>
          <Card.Description>
            <Label ribbon='right' color={statusColor}>
              <Loader active={statusLoader} inline size='mini'/>
              &nbsp;&nbsp;&nbsp;
              {statusName}
            </Label>
            {
              this.props.status.failureReason ?
                <Message negative size='tiny'>
                  <Message.Header>Failure Reason</Message.Header>
                  {this.props.status.failureReason}
                </Message>
              : <div/>
            }
          </Card.Description>
        </Card.Content>
      </Card>
    )
  }
}

export default VUICard;
