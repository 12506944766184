import Amplify from 'aws-amplify';
import axios from 'axios';
import { has } from 'lodash';

import { settings } from './index';

let appSyncConfig = null;

const setupAppSync = async ({ user }) => {
  try {
    if (has(appSyncConfig, 'aws_appsync_apiKey')) {
      // console.log('AppSync is already configured')
      return;
    }

    if (!has(user, 'signInUserSession.idToken.jwtToken')) {
      throw new Error('Authorization error');
    }
    const response = await axios({
      method: 'post',
      url: `${settings.apiUrl}/setup/appsync`,
      headers: {
        'Authorization': user.signInUserSession.idToken.jwtToken
      }
    });
    // console.log('response.data:', response.data);
    appSyncConfig = response.data;
    if (has(appSyncConfig, 'aws_appsync_apiKey')) {
      // console.log('appSyncConfig:', appSyncConfig);
      Amplify.configure(appSyncConfig);
    }
  } catch (err) {
    console.error('Setup Graphql Error:', err);
  }
}

export { setupAppSync };

