import React, { Component } from 'react'
import {
  Icon,
  Message,
  Segment,
  Dropdown,
  Dimmer,
  Loader,
  Statistic,
  // Container,
  Radio,
  Button,
  // Label,
  Table,
  Form,
  Input,
  Header,
} from 'semantic-ui-react'

import {
  Auth,
  Analytics,
} from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import axios from 'axios';
import { has, isEmpty } from 'lodash';

import {
  Vuics,
  Oscilloscope,
  Consumer,
  Synthesizer,
  Recognizer
} from '@vuics/vui-react'

import { AccountLayout } from './components';
import { settings } from './helpers';

class Dashboard extends Component {
  state = {
    selectedName: '',
    loading: true,
    user: {},
    vuiSelectList: [ ],
    recognizing: false,
    intentName: '',
    inputTranscript: '',
    inputText: '',
    message: '',
    slots: { },
    sendingText: false,
    text: '',
  }

  handleChange = (e, { value }) => this.setState({ selectedName: value })

  componentDidMount = async () => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      this.setState({ user });
      Analytics.record({
        name: 'dashboardVisit',
        attributes: {
          userSub: user.attributes.sub,
          username: user.username
        }
      })

      await this.getVUISelectList();
    } catch (err) {
      console.error('Dashboard mount error:', err);
      // TODO: show error in GUI
    }

    const onWakeWord = (greeting, vuiname) => {
      Synthesizer.speak({ phrase: 'Listening' });
      setTimeout(this.wakeUp, 1000);
      Recognizer.abort(); // alternative way: Recognizer.pause();
    }

    const onSleepWord = (farewell, vuiname) => {
      Synthesizer.speak({ phrase: 'Talk to you soon' });
      Recognizer.abort();
      this.setState(() => ({ recognizing: false }));
    }

    Recognizer.addCommands({
      ':greeting :vuiname': {
        'regexp': /^(Hello|Hey|Hi) (Vuics|Voice|Voice Interface|Voice User Interface)$/,
        'callback': onWakeWord
      },
      'Click to Speak': onWakeWord,

      ':farewell :vuiname': {
        'regexp': /^(Goodbye|Bye|Bye-bye) (Vuics|Voice|Voice Interface|Voice User Interface)$/,
        'callback': onSleepWord
      }
    });

    // if (has(user, 'signInUserSession.idToken.jwtToken')) {
    //   try {
    //     const authToken = user.signInUserSession.idToken.jwtToken;
    //     // const response =
    //     await axios({
    //       method: 'get',
    //       url: `${settings.apiUrl}/dashboard`,
    //       headers: {
    //         'Authorization': authToken
    //       }
    //     });
    //     // console.log('response:', response);
    //   } catch (err) {
    //     console.error('Dashboard API Error:', err);
    //   }
    // }
  }

  getVUISelectList = async () => {
    const { user } = this.state;
    try {
      if (!has(user, 'signInUserSession.idToken.jwtToken')) {
        throw new Error('Authorization error');
      }
      this.setState({ loading: true });
      const response = await axios({
        method: 'post',
        url: `${settings.apiUrl}/builder/list`,
        headers: {
          'Authorization': user.signInUserSession.idToken.jwtToken
        }
      });

      this.setState(({ vuiSelectList }) => {
        vuiSelectList = response.data;
        vuiSelectList = vuiSelectList.filter(vuiItem => vuiItem.status === 'READY');
        vuiSelectList = vuiSelectList.map(vuiItem => {
          return {
            key: vuiItem.name,
            text: `${vuiItem.name}: ${vuiItem.description}`,
            value: vuiItem.name,
          };
        })
        return { vuiSelectList, loading: false };
      });

    } catch (err) {
      console.error('Get VUI List Error:', err);
    }
  }

  onData = ({ intentName, inputTranscript, message, slots }) => {
    this.setState({ intentName, inputTranscript, inputText: '', message, slots });
    if (this.state.recognizing) {
      Recognizer.resume();
    }
  }

  savedOnClick = () => {};

  wakeUp = () => { this.savedOnClick(); }

  onRadioChange = (event, data) => {
    if (data.checked) {
      Recognizer.resume();
    } else {
      Recognizer.abort();
    }
    this.setState(() => ({ recognizing: data.checked }));
  }

  handleTextInput = (e, { value }) => this.setState({ text: value })

  sendText = async () => {
    try {
      const { user, text } = this.state;
      this.setState({ sendingText: true, text: '' });
      if (!has(user, 'signInUserSession.idToken.jwtToken')) {
        throw new Error('Authorization error');
      }
      const response = await axios({
        method: 'post',
        url: `${settings.apiUrl}/server/text`,
        headers: {
          'Authorization': user.signInUserSession.idToken.jwtToken
        },
        data: {
          name: this.state.selectedName,
          userId: user.username,
          inputText: text,
        }
      });
      // console.log('response:', response);
      this.setState({
        sendingText: false,
        intentName: response.data.intentName,
        inputTranscript: '',
        inputText: text,
        message: response.data.message,
        slots: response.data.slots,
      });
    } catch (err) {
      console.error('sendText Error:', err);
      this.setState({ sendingText: false, error: err.message });
    }
  }

  componentDidCatch(error, info) {
    console.error('Catched Error:', error, ', info:', info);
    this.setState({ error });
  }

  render = () => {
    return (
      <AccountLayout>
        <Segment>
          <Dimmer active={this.state.loading}>
            <Loader>Loading</Loader>
          </Dimmer>

          <Message attached='top' floating>
            <Header as='h2'>
              <Icon link name='dashboard' />
              <Header.Content>
                Vuics Dashboard
                <Header.Subheader>Talk to your Voice User Interface</Header.Subheader>
              </Header.Content>
            </Header>
          </Message>
          <br/>

          <Statistic.Group widths={1}>
            <Statistic>
              <Statistic.Value>Vuics</Statistic.Value>
              <Statistic.Label>Voice User Interfaces</Statistic.Label>
            </Statistic>
          </Statistic.Group>
          <br/>

          <Dropdown
            fluid
            onChange={this.handleChange}
            options={this.state.vuiSelectList}
            placeholder='Choose VUI'
            selection
            value={this.state.selectedName}
          />
          <br/>

          <Vuics
            name={this.state.selectedName}
            onConversationData={this.onData}
            fillStyle='rgb(255,255,255)'
            lineWidth={1}
            strokeStyle='teal'
            authToken={
              has(this.state.user, 'signInUserSession.idToken.jwtToken') ?
              this.state.user.signInUserSession.idToken.jwtToken : ''
            }
          >
            <Oscilloscope
              canvasWrapperClassName='canvasWrapperDashboard'
              canvasClassName='canvasDashboard'
            />
            <br/>

            <Radio toggle
              style={{ color: 'grey', verticalAlign: 'middle' }}
              disabled={!Recognizer.isSupported() || !this.state.selectedName}
              checked={this.state.recognizing}
              onChange={this.onRadioChange}
            />
            &nbsp; &nbsp;
            {
              Recognizer.isSupported() ? (
                <span>
                Wake-Word <i>"Hello Voice"</i>,
                Sleep-Word <i>"Goodbye Voice"</i>
                </span>
              ) : (
                <span>No browser support of Wake-Word</span>
              )
            }
            <br/>
            <br/>

            <Consumer>
              {
                ({ buttonRef, onClick, state, message }) => {
                  this.savedOnClick = onClick

                  return (
                    <Button fluid
                      className='button'
                      onClick={this.wakeUp}
                      ref={buttonRef}
                      size='big'
                      color='teal'
                      disabled={!this.state.selectedName}
                    >
                      {
                        state === 'Passive'
                          ? 'Click to Speak 🎙️'
                          : state === 'Listening'
                            ? '🎤 Say a Phrase'
                            : state === 'Sending'
                              ? <Loader active size='small' inline='centered' />
                              : '🔊 Listen'
                      }
                    </Button>
                  )
                }
              }
            </Consumer>
          </Vuics>
          <br/>

          <Form>
            <Input fluid
              loading={this.state.sendingText}
              icon='user'
              iconPosition='left'
              action={{
                color: 'blue',
                labelPosition: 'right',
                icon: 'send',
                content: 'Send',
                onClick: this.sendText,
                disabled: !this.state.selectedName
              }}
              value={this.state.text}
              onChange={this.handleTextInput}
              disabled={!this.state.selectedName}
            />
          </Form>

          <br/>
          {
            (this.state.inputTranscript || this.state.inputText) && (
              <Message
                floating
                size='tiny'
                color={this.state.inputTranscript ? 'teal': 'blue'}
              >
                <Message.Header>
                  <Icon name='microphone' />&nbsp;
                  {
                    this.state.inputTranscript ? (
                      <span>Input Transcript</span>
                    ) : (
                      <span>Input Text</span>
                    )
                  }
                </Message.Header>
                <p>{this.state.inputTranscript || this.state.inputText}</p>
              </Message>
            )
          }
          {
            this.state.intentName && (
              <Message info floating size='tiny'>
                <Message.Header>
                  <Icon name='crosshairs' />&nbsp;
                  Intent Name
                </Message.Header>
                <p>{this.state.intentName}</p>
              </Message>
            )
          }
          {
            this.state.message && (
              <Message info floating size='tiny'>
                <Message.Header>
                  <Icon name='bullhorn' />&nbsp;
                  Message
                </Message.Header>
                <p>{this.state.message}</p>
              </Message>
            )
          }
          {
            !isEmpty(this.state.slots) ? (
            <Table celled structured>
              <Table.Header>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell colSpan='2'>
                    <Icon name='tasks' />&nbsp;
                    Slots
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Value</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  Object.keys(this.state.slots).map(key => (
                    <Table.Row key={key}>
                      <Table.Cell>{key}</Table.Cell>
                      <Table.Cell>{this.state.slots[key]}</Table.Cell>
                    </Table.Row>
                  ))
                }
              </Table.Body>
            </Table>
            ) : (
              <div/>
            )
          }

          <br/>
          <Message attached='bottom' warning>
            <Icon name='help' />
            If you would like us to help you, just contact us.
          </Message>
        </Segment>
      </AccountLayout>
    )
  }
}

export default withAuthenticator(Dashboard, { includeGreetings: true })
