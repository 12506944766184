import React, { Component } from 'react'
import { has } from 'lodash'
import {
  Segment,
  Form,
  Icon,
  Message,
  // Table,
  Header,
} from 'semantic-ui-react'

import {
  Auth,
  Analytics,
  API,
  graphqlOperation
} from 'aws-amplify';
import {
  withAuthenticator,
  // Connect
} from 'aws-amplify-react';

import { serializeData, deserializeData, setupAppSync } from './helpers';
import { AccountLayout } from './components';

class Profile extends Component {
  state = {
    loading: true,
    error: null,

    id: null,
    firstName: '',
    lastName: '',
    company: '',
    website: '',

    accountName: null,
    user: null,
  }

  componentDidMount = async () => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      this.setState({ user, accountName: user.username });
      Analytics.record({
        name: 'profileVisit',
        attributes: {
          userSub: user.attributes.sub,
          username: user.username
        }
      })

      await setupAppSync({ user });

      // console.log('accountName:', this.state.accountName);
      const ListProfiles = `query ListProfiles($accountName: String!) {
      	listProfiles(filter: { accountName: { eq: $accountName } }, limit: 1) {
          items {
            id
            firstName
            lastName
            company
            website
          }
        }
      }`;
      const gotProfiles = await API.graphql(graphqlOperation(ListProfiles, { accountName: this.state.accountName }));
      // console.log('gotProfiles:', gotProfiles)
      const profile = deserializeData(gotProfiles.data.listProfiles.items[0]);
      // console.log('profile:', profile);
      this.setState(profile);
    } catch (err) {
      console.error('Mounting error:', err);
    }
    this.setState({ loading: false });
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = async () => {
    try {
      const { id, accountName, firstName, lastName, company, website } = this.state

      const UpdateOrCreateProfile = `mutation ${id ? 'UpdateProfile' : 'CreateProfile'}(
          ${ id ? '$id: ID!,' : ''}
          $accountName: String!,
          $firstName: String,
          $lastName: String,
          $company: String,
          $website: String
        ) {
        ${id ? 'updateProfile' : 'createProfile'}(input: {
          ${ id ? 'id: $id,' : ''}
          accountName: $accountName,
          firstName: $firstName,
          lastName: $lastName,
          company: $company,
          website: $website
        }) {
          id
          firstName
          lastName
          company
          website
        }
      }`;

      let profileData = {
        accountName,
        firstName,
        lastName,
        company,
        website
      };
      if (id) { profileData.id = id; }
      profileData = serializeData(profileData);

      this.setState({ loading: true, error: null });
      const { data } = await API.graphql(graphqlOperation(UpdateOrCreateProfile, profileData));
      let profile;
      if (has(data, 'updateProfile')) {
        profile = data.updateProfile;
      } else if (has(data, 'createProfile')) {
        profile = data.createProfile;
      }
      if (profile) {
        profile = deserializeData(profile);
        this.setState(profile);
      }

      this.setState({ loading: false });
    } catch (err) {
      console.error('handleSubmit Error:', err);
      this.setState({ loading: false });
      if (has(err, 'errors[0].message')) {
        this.setState({ error: err.errors[0].message });
      }
    }
  }

  render = () => {
    const { firstName, lastName, company, website, error } = this.state;

    // const ListProfiles = `query ListProfiles {
    //   listProfiles {
    //     items {
    //       id
    //       accountName
    //       firstName
    //       lastName
    //       company
    //       website
    //       createdAt
    //       updatedAt
    //     }
    //   }
    // }`;

    // const ListProfilesView = ({ profiles }) => (
    //   <Table celled>
    //     <Table.Header>
    //       <Table.Row>
    //         <Table.HeaderCell>accountName</Table.HeaderCell>
    //         <Table.HeaderCell>firstName</Table.HeaderCell>
    //         <Table.HeaderCell>lastName</Table.HeaderCell>
    //         <Table.HeaderCell>company</Table.HeaderCell>
    //         <Table.HeaderCell>website</Table.HeaderCell>
    //         <Table.HeaderCell>createdAt</Table.HeaderCell>
    //         <Table.HeaderCell>updatedAt</Table.HeaderCell>
    //       </Table.Row>
    //     </Table.Header>
    //     <Table.Body>
    //       {profiles.map(profile => {
    //         if (!profile) {
    //           return null;
    //         }
    //         return (
    //           <Table.Row key={profile.id}>
    //             <Table.Cell>{profile.accountName}</Table.Cell>
    //             <Table.Cell>{profile.firstName}</Table.Cell>
    //             <Table.Cell>{profile.lastName}</Table.Cell>
    //             <Table.Cell>{profile.company}</Table.Cell>
    //             <Table.Cell>{profile.website}</Table.Cell>
    //             <Table.Cell>{profile.createdAt}</Table.Cell>
    //             <Table.Cell>{profile.updatedAt}</Table.Cell>
    //           </Table.Row>
    //         )
    //       })}
    //     </Table.Body>
    //   </Table>
    // );

    return (
      <AccountLayout>
        <Segment>
          <Message attached='top' floating>
            <Header as='h2'>
              <Icon link name='user outline' />
              <Header.Content>
                Vuics Profile
                <Header.Subheader>Add info about yourself and your company</Header.Subheader>
              </Header.Content>
            </Header>
          </Message>
          <br/>
          <br/>

          { error ? (
            <Message
              error
              header='Error'
              content={error}
            />
          ) : (
            <div />
          )}
          <Form onSubmit={this.handleSubmit} loading={this.state.loading}>
            <Form.Group widths='equal'>
              <Form.Input fluid label='First Name' placeholder='First Name' name='firstName' value={firstName} onChange={this.handleChange} />
              <Form.Input fluid label='Last Name' placeholder='Last Name' name='lastName' value={lastName} onChange={this.handleChange} />
            </Form.Group>
            <Form.Input label='Company' placeholder='Company' name='company' value={company} onChange={this.handleChange} />
            <Form.Input label='Website URL' placeholder='https://' name='website' value={website} onChange={this.handleChange} />
            <Form.Button content='Submit' />
          </Form>
          <br/>
          <Message attached='bottom' warning>
            <Icon name='help' />
            If you would like us to help you, just contact us.
          </Message>

          {/*
          <Connect query={graphqlOperation(ListProfiles)}>
            {({ data }) => {
              if (has(data, 'listProfiles.items')) {
                const profiles = data.listProfiles.items.map(x => deserializeData(x));
                return (<ListProfilesView profiles={profiles} />)
              }
            }}
          </Connect>
          */}
        </Segment>
      </AccountLayout>
    )
  }
}

export default withAuthenticator(Profile, { includeGreetings: true })
