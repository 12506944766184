const serializeData = (data) => {
  Object.keys(data).forEach((key) => {
    let val = data[key];
    if (val === null) {
      data[key] = '<PLACEHOLDER_NULL>';
    }
    if (val === '') {
      data[key] = '<PLACEHOLDER_EMPTYSTRING>';
    }
  });
  return data;
}

const deserializeData = (data) => {
  if (data !== undefined && data !== null) {
    Object.keys(data).forEach((key) => {
      let val = data[key];
      if (val === '<PLACEHOLDER_NULL>') {
        data[key] = null;
      }
      if (val === '<PLACEHOLDER_EMPTYSTRING>') {
        data[key] = '';
      }
    });
  }
  return data;
}

export { serializeData, deserializeData };
