import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Rail,
  Sticky,
  Header,
  Menu,
  Icon,
  Container
} from 'semantic-ui-react'
import {
  Link,
  withRouter
} from 'react-router-dom';

class AccountLayout extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  }

  state = {
    contextRef: null,
  }

  handleContextRef = contextRef => this.setState({ contextRef })

  render = () => {
    const { contextRef } = this.state;
    const { pathname } = this.props.location;

    return (
      <Grid centered columns={3}>
        <Grid.Column>
          <div ref={this.handleContextRef}>
            <Rail position='left'>
              <Sticky context={contextRef}>
                <br/>
                <Header as='h3'>Vuics Account</Header>
                <Menu fluid vertical tabular>
                  <Menu.Item active={pathname === '/dashboard'} >
                    <Link to='/dashboard'>
                      <Icon link name='dashboard' />
                      Dashboard
                    </Link>
                  </Menu.Item>
                  <Menu.Item active={pathname === '/builder'} >
                    <Link to='/builder'>
                      <Icon link name='code' />
                      Builder
                    </Link>
                  </Menu.Item>
                  <Menu.Item active={pathname === '/docs'} >
                    <Link to='/docs'>
                      <Icon link name='file alternate outline' />
                      Documentation
                    </Link>
                  </Menu.Item>
                  <Menu.Item active={pathname === '/analytics'} >
                    <Link to='/analytics'>
                      <Icon link name='line graph' />
                      Analytics
                    </Link>
                  </Menu.Item>
                  <Menu.Item active={pathname === '/profile'} >
                    <Link to='/profile'>
                      <Icon link name='user outline' />
                      Profile
                    </Link>
                  </Menu.Item>
                  <Menu.Item active={pathname === '/settings'} >
                    <Link to='/settings'>
                      <Icon link name='settings' />
                      Settings
                    </Link>
                  </Menu.Item>
                </Menu>
              </Sticky>
            </Rail>

            <Container text>
              {this.props.children}
            </Container>
          </div>
        </Grid.Column>
      </Grid>
    )
  }
}

export default withRouter(AccountLayout);
