import React, { Component } from 'react'
import {
  Icon,
  Message,
  Segment,
  Form,
  Input,
  Button,
  Dimmer,
  Loader,
  Header,
} from 'semantic-ui-react'

import {
  Auth,
  Analytics,
} from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import axios from 'axios';
import { has } from 'lodash';

import { AccountLayout } from './components';
import { settings } from './helpers';

class Settings extends Component {
  state = {
    user: null,

    loading: true,
    uploading: false,

    key: null,
    plans: [],

    keyHidden: true,
  }

  componentDidMount = async () => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      // console.log('user:', user);
      this.setState({ user });
      Analytics.record({
        name: 'dashboardVisit',
        attributes: {
          userSub: user.attributes.sub,
          username: user.username
        }
      })
      await this.getSettings();
    } catch (err) {
      console.error('Authentication Error:', err);
      return;
    }
  }

  getSettings = async () => {
    const { user } = this.state;

    if (has(user, 'signInUserSession.idToken.jwtToken')) {
      try {
        this.setState({ loading: true });
        const authToken = user.signInUserSession.idToken.jwtToken;
        const response = await axios({
          method: 'get',
          url: `${settings.apiUrl}/settings`,
          headers: {
            'Authorization': authToken
          }
        });
        // console.log('response:', response);
        if (has(response, 'data.name') && response.data.name === 'Error') {
          throw new Error(response.data.message);
        }
        this.setState({
          key: has(response.data, 'key') ? response.data.key : null,
          plans: has(response.data, 'plans.items') ? response.data.plans.items : null,
        });
      } catch (err) {
        console.error('Get settings error:', err);
      }
    }
    this.setState({ loading: false });
  }

  toggleShow = () => {
    this.setState({ keyHidden: !this.state.keyHidden });
  }

  createApiKey = async () => {
    // console.log('createApiKey');
    const { user } = this.state;

    if (has(user, 'signInUserSession.idToken.jwtToken')) {
      try {
        this.setState({ uploading: true });
        const authToken = user.signInUserSession.idToken.jwtToken;
        const response = await axios({
          method: 'post',
          url: `${settings.apiUrl}/settings`,
          headers: {
            'Authorization': authToken
          },
        });
        // console.log('response:', response);
        if (has(response, 'data.name') && response.data.name === 'Error') {
          throw new Error(response.data.message);
        }
        this.setState({
          key: has(response.data, 'key') ? response.data.key : null,
          plans: has(response.data, 'plans.items') ? response.data.plans.items : null,
        });
      } catch (err) {
        console.error('Post settings error:', err);
      }
    }
    this.setState({ uploading: false });
  }

  render = () => {
    let plan = { name: '' };
    if (has(this.state, 'plans[0]')) {
      plan = this.state.plans[0];
    }
    let quota = {
      limit: 0,
      offset: 0,
      period: 'MONTH'
    };
    if (has(plan, 'quota')) {
      quota = plan.quota;
    }
    // console.log('key:', this.state.key);
    // console.log('plans:', this.state.plans);

    const settingsForm = (
      <Form loading={this.state.loading}>
        <Form.Field>
          <label>Plan</label>
          <Message positive size='tiny'>{plan.name}</Message>
          <Button color='teal' disabled>Upgrade</Button>
        </Form.Field>

        <Form.Field>
          <label>Quota</label>
          <Message
            size='tiny'
            positive={quota.offset < quota.limit}
            negative={quota.offset >= quota.limit}
          >
            {quota.offset} &nbsp; / &nbsp;
            {quota.limit} &nbsp; per &nbsp;
            {quota.period}
          </Message>
        </Form.Field>

        <Form.Field>
          <label>API Key</label>
          <Input
            type='text' fluid placeholder='API Key' readOnly
            action={{
              color: 'teal',
              labelPosition: 'right',
              icon: 'eye',
              content: this.state.keyHidden ? 'Show' : 'Hide',
             onClick: this.toggleShow
            }}
            value={this.state.keyHidden ? '•'.repeat(40) : this.state.key.value }
          />
        </Form.Field>

        {/*
        <Form.Field>
          <label>Allow CORS</label>
          <Message size='tiny'>
            List of allowed CORS
          </Message>
        </Form.Field>
        */}
      </Form>
    );

    const initForm = (
      <Form loading={this.state.uploading}>
        <Message color='yellow'>
          You don't have API key. You could create an API key.
        </Message>
        <Button color='yellow' onClick={this.createApiKey}>
          Create API Key
        </Button>
        <br/>
      </Form>
    );

    return (
      <AccountLayout>
        <Segment>
          <Dimmer active={this.state.loading}>
            <Loader />
          </Dimmer>

          <Message attached='top' floating>
            <Header as='h2'>
              <Icon link name='settings' />
              <Header.Content>
                Vuics Settings
                <Header.Subheader>Setup your plan and API key</Header.Subheader>
              </Header.Content>
            </Header>
          </Message>
          <br/>

          {
            !this.state.loading && this.state.key && settingsForm
          }
          {
            !this.state.loading && !this.state.key && initForm
          }

          <br/>
          <Message attached='bottom' warning>
            <Icon name='help' />
            If you would like us to help you, just contact us.
          </Message>
        </Segment>
      </AccountLayout>
    )
  }
}

export default withAuthenticator(Settings, { includeGreetings: true })
