import React, { Component } from 'react'
import {
  Icon,
  Message,
  Segment,
  // Table,
  // Pagination,
  Button,
  Dimmer,
  Loader,
  Input,
  Select,
  Card,
  Header,
} from 'semantic-ui-react'

import {
  Auth,
  Analytics as AmplifyAnalytics,
  graphqlOperation,
  API,
} from 'aws-amplify';
import {
  withAuthenticator,
  // Connect
} from 'aws-amplify-react';
import { has } from 'lodash';
import moment from 'moment';

import { AccountLayout } from './components';
import { deserializeData, setupAppSync } from './helpers';

class Analytics extends Component {
  state = {
    userId: '',
    accountName: '',
    nextToken: null,
    error: '',
    interactions: [],
    loadMoreDisabled: false,
    loading: true,
    searchValue: '',
    selectedOperation: 'contains',
    selectedField: 'name',
    useSearchFilter: false,
    selectedAmount: 10,
  }

  componentDidMount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.setState({
        userId: user.attributes.sub,
        accountName: user.username,
      });
      AmplifyAnalytics.record({
        name: 'analyticsVisit',
        attributes: {
          userSub: user.attributes.sub,
          username: user.username
        }
      })
      await setupAppSync({ user });
      await this.getInteractions();
    } catch (err) {
      console.error('componentDidMount Error:', err);
      throw err;
    }
  }

  // https://reactjs.org/docs/react-component.html#componentdidcatch
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error('Catched Error:', error, ', info:', info);
    this.setState({ error });
  }

  getInteractions = async () => {
    const {
      accountName, useSearchFilter, searchValue,
      selectedOperation, selectedField, nextToken,
    } = this.state;

    this.setState({ loading: true });

    let ListInteractions;
    const ListInteractionsAll = `
      query ListInteractions (
        $accountName: String,

        $limit: Int,
        $nextToken: String
      ) {
        listInteractions(
          filter: {
            accountName: { eq: $accountName },
          },
          limit: $limit,
          nextToken: $nextToken
        ) {
          items {
            id
            accountName
            name
            userId
            input
            inputTranscript
            inputText
            intentName
            message
            slots
            dialogState
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `;

    const ListInteractionsEq = `
      query ListInteractions (
        $accountName: String,

        $name: String,
        $input: String,
        $inputTranscript: String,
        $inputText: String,
        $intentName: String,
        $message: String,
        $slots: String,
        $dialogState: String,
        $userId: String,

        $limit: Int,
        $nextToken: String
      ) {
        listInteractions(
          filter: {
            and: [
              { accountName: { eq: $accountName } },
              { or: [
                { name: { eq: $name } },
                { userId: { eq: $userId } },
                { input: { eq: $input } },
                { inputTranscript: { eq: $inputTranscript } },
                { inputText: { eq: $inputText } },
                { intentName: { eq: $intentName } },
                { message: { eq: $message } },
                { slots: { eq: $slots } },
                { dialogState: { eq: $dialogState } },
              ] },
            ],
          },
          limit: $limit,
          nextToken: $nextToken
        ) {
          items {
            id
            accountName
            name
            userId
            input
            inputTranscript
            inputText
            intentName
            message
            slots
            dialogState
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `;
    const ListInteractionsContains = `
      query ListInteractions (
        $accountName: String,

        $name: String,
        $input: String,
        $inputTranscript: String,
        $inputText: String,
        $intentName: String,
        $message: String,
        $slots: String,
        $dialogState: String,
        $userId: String,

        $limit: Int,
        $nextToken: String
      ) {
        listInteractions(
          filter: {
            and: [
              { accountName: { eq: $accountName } },
              { or: [
                { name: { contains: $name } },
                { userId: { contains: $userId } },
                { input: { contains: $input } },
                { inputTranscript: { contains: $inputTranscript } },
                { inputText: { contains: $inputText } },
                { intentName: { contains: $intentName } },
                { message: { contains: $message } },
                { slots: { contains: $slots } },
                { dialogState: { contains: $dialogState } },
              ] },
            ],
          },
          limit: $limit,
          nextToken: $nextToken
        ) {
          items {
            id
            accountName
            name
            userId
            input
            inputTranscript
            inputText
            intentName
            message
            slots
            dialogState
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `;

    const interactionsDetails = {
      accountName,

      name: null,
      userId: null,
      input: null,
      inputTranscript: null,
      inputText: null,
      intentName: null,
      message: null,
      slots: null,
      dialogState: null,

      limit: this.state.selectedAmount,
      nextToken,
    };
    if (useSearchFilter && searchValue && selectedField) {
      interactionsDetails[selectedField] = searchValue ?  searchValue : null;
      if (selectedOperation === 'eq') {
        ListInteractions = ListInteractionsEq;
      } else if (selectedOperation === 'contains') {
        ListInteractions = ListInteractionsContains;
      }
    } else {
      ListInteractions = ListInteractionsAll;
    }

    const result = await API.graphql(graphqlOperation(ListInteractions, interactionsDetails));

    const { data, loading, error } = result;
    if (error) {
      throw new Error(error);
    }
    if (loading || !has(data, 'listInteractions.items')) {
      return;
    }
    const newInteractions = data.listInteractions.items.map(x => deserializeData(x));
    this.setState(({ interactions }) => {
      interactions = interactions.concat(newInteractions);
      return {
        interactions,
        nextToken: data.listInteractions.nextToken,
        loadMoreDisabled: !data.listInteractions.nextToken,
        loading: false,
      }
    });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  handleLoadMore = async () => {
    await this.getInteractions();
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ searchValue: value });
  }

  handleSelectOperationChange = (e, { value }) => {
    this.setState({ selectedOperation: value });
  }

  handleSelectFieldChange = (e, { value }) => {
    this.setState({ selectedField: value });
  }

  handleSelectAmountChange = (e, { value }) => {
    this.setState({ selectedAmount: value });
  }

  handleSearchReset = async () => {
    await this.setStateAsync({
      searchValue: '',
      useSearchFilter: false,
      interactions: [],
      nextToken: null,
    });
    await this.getInteractions();
  }

  handleSearchClick = async () => {
    await this.setStateAsync({
      useSearchFilter: true,
      interactions: [],
      nextToken: null
    });
    await this.getInteractions();
  }

  render = () => {
    const ListInteractionsView = ({ interactions }) => (
      <Card.Group>
        { interactions && interactions.map(interaction => {
            if (!interaction) {
              return null;
            }
            return (
              <Card key={interaction.id}>
                <Card.Content>
                  <Card.Header>Name: {interaction.name}</Card.Header>
                  <Card.Meta>
                    <Icon name='user circle outline' />&nbsp;
                    User Id: <b>{interaction.userId}</b>
                  </Card.Meta>
                    <Icon name='crosshairs' />&nbsp;
                    Intent Name: <b>{interaction.intentName}</b><br/>

                    <Icon name='microphone' />&nbsp;
                    Input&nbsp;
                    {
                      interaction.input ? '' :
                      interaction.inputTranscript ? '(Transcript)' :
                      interaction.inputText ? '(Text)' :
                      ''
                    }: <b>{
                      interaction.input ||
                      interaction.inputTranscript ||
                      interaction.inputText
                    }</b><br/>

                    <Icon name='bullhorn' />&nbsp;
                    Message: <b>{interaction.message}</b><br/>
                  <Card.Description>
                  <Icon name='tasks' />&nbsp;
                  Slots: <b>{JSON.stringify(JSON.parse(interaction.slots), null, 2)}</b><br/>

                  <Icon name='th' />&nbsp;
                  DialogState: <b>{interaction.dialogState}</b>
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Icon name='clock outline' />&nbsp;
                  <b>{moment().from(interaction.createdAt, true)}</b>&nbsp;
                  <i>(Updated: {moment().from(interaction.updatedAt, true)})</i>
                </Card.Content>
              </Card>
            )
          })
        }
      </Card.Group>
    );

    const operationOptions = [
      { key: 'eq', text: 'Equal', value: 'eq' },
      { key: 'contains', text: 'Contains', value: 'contains' },
    ];

    const fieldOptions = [
      { key: 'name', text: 'Name', value: 'name' },
      { key: 'userId', text: 'User Id', value: 'userId' },
      { key: 'intentName', text: 'Intent Name', value: 'intentName' },
      { key: 'input', text: 'Input', value: 'input' },
      { key: 'inputTranscript', text: 'Input Transcript', value: 'inputTranscript' },
      { key: 'inputText', text: 'Input Text', value: 'inputText' },
      { key: 'message', text: 'Message', value: 'message' },
      { key: 'slots', text: 'Slots', value: 'slots' },
      { key: 'dialogState', text: 'Dialog State', value: 'dialogState' },
    ];

    const amountOptions = [
      { key: '10', text: '10', value: 10 },
      { key: '20', text: '20', value: 20 },
      { key: '50', text: '50', value: 50 },
      { key: '100', text: '100', value: 100 },
    ];

    return (
      <AccountLayout>
        <Segment>
          <Message attached='top' floating>
            <Header as='h2'>
              <Icon link name='line graph' />
              <Header.Content>
                Vuics Analytics
                <Header.Subheader>Analyze and optimize your Voice User Interface</Header.Subheader>
              </Header.Content>
            </Header>
          </Message>
          <br/>

          { this.state.error && (
            <Message
              error
              header='Error'
              content={this.state.error}
            />
          ) }

          <Segment>
            <Dimmer active={this.state.loading}>
              <Loader>Loading</Loader>
            </Dimmer>

            <Input
              type='text' placeholder='Search...' fluid action
              value={this.state.searchValue}
              onChange={this.handleSearchChange}
            >
              <input />
              <Button onClick={this.handleSearchReset} icon basic color='red'>
                <Icon name='cancel' />
              </Button>
              <Button onClick={this.handleSearchClick} color='teal'>
                Search
                &nbsp;
                <Icon name='search' />
              </Button>
            </Input>
            <br/>
            <Icon name='filter' />
            &nbsp;
            <Select
              compact
              placeholder='Show amount'
              options={amountOptions}
              defaultValue={this.state.selectedAmount}
              onChange={this.handleSelectAmountChange}
            />
            &nbsp;
            <Select compact
              options={operationOptions}
              defaultValue={this.state.selectedOperation}
              onChange={this.handleSelectOperationChange}
            />
            &nbsp;
            <Select
              options={fieldOptions}
              defaultValue={this.state.selectedField}
              onChange={this.handleSelectFieldChange}
            />
            <br/>
            <br/>

            <ListInteractionsView interactions={this.state.interactions} />

            <br/>
            <Button
              attached='bottom'
              onClick={this.handleLoadMore}
              disabled={this.state.loadMoreDisabled}
            >
              Load more
            </Button>
          </Segment>

          <br/>
          <Message attached='bottom' warning>
            <Icon name='help' />
            If you would like us to help you, just contact us.
          </Message>
        </Segment>
      </AccountLayout>
    )
  }
}

export default withAuthenticator(Analytics, { includeGreetings: true })
