import React, { Component } from 'react'
import {
  Icon,
  Message,
  Segment,
  Accordion,
  Menu,
  Dropdown,
  Dimmer,
  Loader,
  Header,
} from 'semantic-ui-react'

import {
  Auth,
  Analytics,
} from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import axios from 'axios';
import { has } from 'lodash';
import uuidv4 from 'uuid/v4';

import { AccountLayout, VUIEditor } from './components';
import { settings } from './helpers';
import {
  HelloWorldExample, HowAreYouExample, OrderCoffeeExample, WebsiteExample,
} from './examples';

class Builder extends Component {
  state = {
    activeUuid: '',
    activeName: '',
    loading: true,
    user: {},
    vuiList: [ ]
  }

  setActive = (e, titleProps) => {
    const { index } = titleProps
    const { activeUuid } = this.state
    const newIndex = activeUuid === index ? -1 : index
    this.setState({ activeUuid: newIndex })
  }

  componentDidMount = async () => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
      this.setState({ user });
      // console.log('user:', user);
      Analytics.record({
        name: 'dashboardVisit',
        attributes: {
          userSub: user.attributes.sub,
          username: user.username
        }
      })

      await this.getVUIList();
    } catch (err) {
      console.error('Builder mount error:', err);
      return;
    }
  }

  getVUIList = async () => {
    const { user } = this.state;
    try {
      if (!has(user, 'signInUserSession.idToken.jwtToken')) {
        throw new Error('Authorization error');
      }
      const response = await axios({
        method: 'post',
        url: `${settings.apiUrl}/builder/list`,
        headers: {
          'Authorization': user.signInUserSession.idToken.jwtToken
        }
      });
      // console.log('response:', response);
      this.setState(({ vuiList, activeUuid, activeName }) => {
        vuiList = vuiList.filter(vuiItem => vuiItem.newOne);
        vuiList = vuiList.concat(response.data);
        vuiList = vuiList.map(vuiItem => {
          if (!vuiItem.uuid) {
            vuiItem.uuid = uuidv4();
          }
          if (vuiItem.name === activeName) {
            activeUuid = vuiItem.uuid;
          }
          return vuiItem;
        })
        return { vuiList, activeUuid, activeName: '', loading: false };
      });
    } catch (err) {
      console.error('Get VUI List Error:', err);
    }
  }

  create = ({ vuiJson }) => {
    this.setState(({ vuiList }) => {
      const vuiItem = {
        name: '<New VUI>',
        newOne: true,
        uuid: uuidv4(),
      }
      if (vuiJson) {
        vuiItem.vuiJson = vuiJson;
      }
      if (has(vuiJson, 'name')) {
        vuiItem.name = vuiJson.name
      }

      vuiList.unshift(vuiItem);
      return { vuiList, activeUuid: vuiItem.uuid };
    });
  }

  exampleHelloWorld = () => {
    this.create({ vuiJson: HelloWorldExample });
  }

  exampleHowAreYou = () => {
    this.create({ vuiJson: HowAreYouExample });
  }

  exampleOrderCoffee = () => {
    this.create({ vuiJson: OrderCoffeeExample });
  }

  exampleWebsite = () => {
    this.create({ vuiJson: WebsiteExample });
  }

  updateTheComponent = async ({ removeUuid, activeName }) => {
    if (removeUuid) {
      this.setState(({ vuiList }) => {
        vuiList = vuiList.filter(vuiItem => vuiItem.uuid !== removeUuid);
        return { vuiList };
      });
    }
    if (activeName) {
      this.setState({ activeName });
    }
    await this.getVUIList();
  }

  render = () => {
    const { activeUuid, loading, vuiList } = this.state;

    const vuiTabs = vuiList.map((vuiItem) => {
      return (
        <div key={'vuiItem-' + vuiItem.uuid}>
          <Accordion.Title
            active={activeUuid === vuiItem.uuid}
            index={vuiItem.uuid}
            onClick={this.setActive}
          >
            <Icon name='dropdown' />
            {vuiItem.name}
          </Accordion.Title>
          <Accordion.Content active={activeUuid === vuiItem.uuid}>

            <VUIEditor
              name={vuiItem.name}
              uuid={vuiItem.uuid}
              user={this.state.user}
              newOne={!!vuiItem.newOne}
              updateParent={this.updateTheComponent}
              vuiJson={vuiItem.vuiJson}
            />

          </Accordion.Content>
        </div>
      );
    });

    return (
      <AccountLayout>
        <Segment>
          <Dimmer active={loading}>
            <Loader>Loading</Loader>
          </Dimmer>

          <Message attached='top' floating>
            <Header as='h2'>
              <Icon link name='code' />
              <Header.Content>
                Vuics Builder
                <Header.Subheader>Build your Voice User Interface</Header.Subheader>
              </Header.Content>
            </Header>
          </Message>
          <br/>

          <Menu>
            <Menu.Item onClick={this.create}>Create VUI</Menu.Item>
            <Dropdown text='File' pointing className='link item'>
              <Dropdown.Menu>
                <Dropdown.Item disabled>Upload from file...</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown text='Examples' pointing className='link item'>
              <Dropdown.Menu>
                <Dropdown.Header>Simple</Dropdown.Header>
                <Dropdown.Item onClick={this.exampleHelloWorld}>Hello World!</Dropdown.Item>
                <Dropdown.Item onClick={this.exampleHowAreYou}>How are you?</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Header>Advanced</Dropdown.Header>
                <Dropdown.Item onClick={this.exampleOrderCoffee}>Order Coffee</Dropdown.Item>
                <Dropdown.Item onClick={this.exampleWebsite}>Website Example</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu>

          <Accordion fluid styled>
            {vuiTabs}
          </Accordion>

          <br/>
          <Message attached='bottom' warning>
            <Icon name='help' />
            If you would like us to help you, just contact us.
          </Message>
        </Segment>
      </AccountLayout>
    )
  }
}

export default withAuthenticator(Builder, { includeGreetings: true })
