// https://lollyrock.com/articles/nodejs-encryption/
// Part of https://github.com/chris-rock/node-crypto-examples

// Nodejs encryption with CTR
const crypto = require('crypto');

const algorithm = 'aes-256-ctr';
const password = 'd6F3Efeqdafweq32fa';

const encrypt = (text) => {
  const cipher = crypto.createCipher(algorithm, password)
  let crypted = cipher.update(text, 'utf8', 'hex')
  crypted += cipher.final('hex');
  return crypted;
}

const decrypt = (text) => {
  const decipher = crypto.createDecipher(algorithm, password)
  let dec = decipher.update(text, 'hex', 'utf8')
  dec += decipher.final('utf8');
  return dec;
}

// const hw = encrypt("hello world")
// // outputs hello world
// console.log(decrypt(hw));

export { encrypt, decrypt };
