import React, { Component } from 'react'
import {
  Table,
  Icon,
  Button,
  Header,
  Segment,
  Container,
  Message,
} from 'semantic-ui-react'

class Pricing extends Component {
  render = () => (
    <Container text>
      <br/>
      <Message attached='top' floating>
        <Header as='h2'>
          <Icon link name='balance scale' />
          <Header.Content>
            Vuics Pricing
            <Header.Subheader>Select the best plan for you</Header.Subheader>
          </Header.Content>
        </Header>
      </Message>

      <Segment>
        <p>
          We provide several subscription plans with unique features.
        </p>

        <Table celled definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Startup School Deal (Free Tier)</Table.HeaderCell>
              <Table.HeaderCell>Personal Plan</Table.HeaderCell>
              <Table.HeaderCell>Team Plan</Table.HeaderCell>
              <Table.HeaderCell>Enterprise Plan</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row positive>
              <Table.Cell>API requests</Table.Cell>
              <Table.Cell>
                500/mo<br/>
                (extendable)
              </Table.Cell>
              <Table.Cell>2k/mo</Table.Cell>
              <Table.Cell>10k/mo</Table.Cell>
              <Table.Cell>100k/mo</Table.Cell>
            </Table.Row>
            <Table.Row positive>
              <Table.Cell>Vuics API</Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
            </Table.Row>
            <Table.Row positive>
              <Table.Cell>Web Speech API</Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
            </Table.Row>
            <Table.Row positive>
              <Table.Cell>Natural Language Understanding</Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
            </Table.Row>
            <Table.Row positive>
              <Table.Cell>Wake-Word and Sleep-Word</Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
            </Table.Row>
            <Table.Row positive>
              <Table.Cell>Dashboard</Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
            </Table.Row>
            <Table.Row positive>
              <Table.Cell>CLI VUI Builder</Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
            </Table.Row>
            <Table.Row positive>
              <Table.Cell>Web VUI Builder</Table.Cell>
              <Table.Cell positive>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell positive>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell positive>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell positive>
                <Icon name='checkmark' />
              </Table.Cell>
            </Table.Row>
            <Table.Row positive>
              <Table.Cell>VUI Analytics</Table.Cell>
              <Table.Cell positive>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell positive>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell positive>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell positive>
                <Icon name='checkmark' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Notification System</Table.Cell>
              <Table.Cell negative>
                <Icon name='close' />
              </Table.Cell>
              <Table.Cell negative>
                <Icon name='close' />
              </Table.Cell>
              <Table.Cell info>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell info>
                <Icon name='checkmark' />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Phone line connection</Table.Cell>
              <Table.Cell negative>
                <Icon name='close' />
              </Table.Cell>
              <Table.Cell negative>
                <Icon name='close' />
              </Table.Cell>
              <Table.Cell negative>
                <Icon name='close' />
              </Table.Cell>
              <Table.Cell info>
                <Icon name='checkmark' />
              </Table.Cell>
            </Table.Row>
            <Table.Row positive>
              <Table.Cell>Support</Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
              <Table.Cell>
                <Icon name='checkmark' />
              </Table.Cell>
            </Table.Row>
            <Table.Row positive>
              <Table.Cell>Price</Table.Cell>
              <Table.Cell>
                Free
              </Table.Cell>
              <Table.Cell>
                {/*
                $19/mo
                */}
              </Table.Cell>
              <Table.Cell>
                {/*
                $79/mo
                */}
              </Table.Cell>
              <Table.Cell>
                {/*
                $499/mo
                */}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <Button color='yellow'>
                  <a style={{ color: 'white' }} href="mailto:admin@vuics.com?subject=SUS Starup Deal&body=Hi Vuics Team, I want to utilize Vuics Startup School Deal. <Type the deal code here>. Thanks.">
                    Utilize!
                  </a>
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Button color='green'>
                  <a style={{ color: 'white' }} href="mailto:admin@vuics.com?subject=Personal Plan&body=Hi Vuics Team, I want to subscribe on Vuics Personal Plan. Thanks.">
                    Contact
                    {/*
                    Subscribe
                    */}
                  </a>
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Button color='green'>
                  <a style={{ color: 'white' }} href="mailto:admin@vuics.com?subject=Team Plan&body=Hi Vuics Team, I want to subscribe on Vuics Team Plan. Thanks.">
                    Contact
                    {/*
                    Subscribe
                    */}
                  </a>
                </Button>
              </Table.Cell>
              <Table.Cell>
                <Button color='green'>
                  <a style={{ color: 'white' }} href="mailto:admin@vuics.com?subject=Enterprise Plan&body=Hi Vuics Team, I want to subscribe on Vuics Enterprise Plan. Thanks.">
                    Contact
                    {/*
                    Subscribe
                    */}
                  </a>
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>

      <Segment>
        <Header as='h2' style={{ fontSize: '2em' }}>
          VUI Integration Pricing
        </Header>

        <p>
          VUI integration made by third-party developers is free.
        </p>
        <p>
          Optionally, we could provide voice interface integration by our software engineers.&nbsp;
          {/*
          The integration work could cost approximately $50/hr.
          */}
          The amount of hours depends on VUI complexity. Please, contact us to get estimations.
        </p>
        <Button color='teal'>
          <a style={{ color: 'white' }} href="mailto:admin@vuics.com?subject=Request VUI Integration&body=Hi Vuics Team, I want to request Vuics Integration. Thanks.">
            Request Vuics Integraton
          </a>
        </Button>
      </Segment>
    </Container>
  )
}

export default Pricing
