import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Icon,
  Message,
  Segment,
  // Accordion,
  Menu,
  Dropdown,
  Sidebar,
  Button,
  Header,
  Dimmer,
  Loader,
  Form,
  // TextArea,
  Confirm,
  // Card,
  Label,
  List,
  Modal,
  Input,
} from 'semantic-ui-react'

import {
  Link,
} from 'react-router-dom';
import { Analytics } from 'aws-amplify';
import axios from 'axios';
import { has, isEmpty } from 'lodash';
import errorToJSON from 'error-to-json';
import uuidv4 from 'uuid/v4';

import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

import VUICard from './VUICard';
import { settings } from '../helpers';

class VUIEditor extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    newOne: PropTypes.bool,
    updateParent: PropTypes.func,
    vuiJson: PropTypes.object,
  }

  static defaultProps = {
    newOne: false
  }

  state = {
    newOne: false,
    visible: false,
    openedDeleteConfirm: false,
    openedResetConfirm: false,
    openedTestModal: false,
    light: true,
    loading: false,
    uploading: false,
    statusing: false,
    deleting: false,
    checkingStatusContinuosly: false,
    error: null,
    vuiJson: {},
    editorObject: {},
    status: {},
    text: '',
    messages: []
  }

  constructor(props) {
    super(props);
    this.state.newOne = this.props.newOne;
    this.state.name = this.props.name;
    if (this.props.vuiJson) {
      this.state.vuiJson = this.props.vuiJson;
    }
    this._timerUpdateStatus = null;
  }

  reset = async () => {
    this.setState({ loading: true, vuiJson: {} });
    await new Promise((resolve) => {
      setTimeout(() => {
        this.setState({
          newOne: this.props.newOne,
          name: this.props.name,
          vuiJson: this.props.vuiJson ? this.props.vuiJson : {},
          loading: false,
        });
        resolve();
      }, 0);
    });
  }

  handleSidebarToggle = () => this.setState(({ visible }) => ({ visible: !visible }))

  handleSchemaToggle = () => this.setState(({ light }) => ({ light: !light }))

  showDeleteConfirm = () => this.setState({ openedDeleteConfirm: true })

  handleDeleteConfirm = async () => {
    this.setState({ openedDeleteConfirm: false })
    await this.deleteVUI();
  }

  handleDeleteCancel = () => this.setState({ openedDeleteConfirm: false })

  showResetConfirm = () => this.setState({ openedResetConfirm: true })

  handleResetConfirm = async () => {
    try {
      this.setState({ openedResetConfirm: false })
      if (this.state.newOne) {
        await this.reset();
      } else {
        await this.getVUI();
      }
    } catch (err) {
      console.error('Reset error:', err);
    }
  }

  handleResetCancel = () => this.setState({ openedResetConfirm: false })

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  handleInput = (e, { value }) => this.setState({ text: value })

  handleTestOpen = () => this.setState({ openedTestModal: true })

  handleTestClose = () => this.setState({ openedTestModal: false })

  componentDidMount = async () => {
    const { user } = this.props;
    Analytics.record({
      name: 'vuieditorVisit',
      attributes: {
        userSub: user.attributes.sub,
        username: user.username
      }
    })

    if (!this.state.newOne) {
      await this.statusVUI();
      await this.getVUI();
    }
  }

  componentWillUnmount = () => {
    if (this._timerUpdateStatus) {
      clearTimeout(this._timerUpdateStatus);
    }
  }

  getVUI = async () => {
    try {
      const { user } = this.props;
      this.setState({ vuiJson: {}, loading: true });
      if (!has(user, 'signInUserSession.idToken.jwtToken')) {
        throw new Error('Authorization error');
      }
      const response = await axios({
        method: 'post',
        url: `${settings.apiUrl}/builder/get`,
        headers: {
          'Authorization': user.signInUserSession.idToken.jwtToken
        },
        data: {
          name: this.state.name
        }
      });
      // console.log('response:', response);
      this.setState({ loading: false, vuiJson: response.data });
    } catch (err) {
      console.error('Get VUI Error:', err);
      this.setState({ loading: false, error: err.message });
    }
  }

  putVUI = async () => {
    try {
      const { user } = this.props;
      let { vuiJson, editorObject } = this.state;

      if (!isEmpty(editorObject)) {
        vuiJson = editorObject;
      }
      // console.log('vuiJson:', vuiJson);
      // console.log('vuiJson.name:', vuiJson.name);
      if (!has(vuiJson, 'name')) {
        throw new Error('Omitted name property.')
      }
      const { name } = vuiJson;
      // console.log('name:', name);
      // console.log('vuiJson:', vuiJson);
      this.setState({ status: {}, uploading: true, vuiJson, name });
      if (!has(user, 'signInUserSession.idToken.jwtToken')) {
        throw new Error('Authorization error');
      }
      // const response = 
      await axios({
        method: 'post',
        url: `${settings.apiUrl}/builder/put`,
        headers: {
          'Authorization': user.signInUserSession.idToken.jwtToken
        },
        data: vuiJson
      });
      // console.log('response:', response);
      this.setState({
        // status: response.data,
        uploading: false
      });

      await this.props.updateParent({ removeUuid: this.props.uuid, activeName: name });
    } catch (err) {
      this.setState({ uploading: false, error: errorToJSON(err).message });
      console.error('Put VUI Error:', err);
    }
  }

  statusVUI = async ({ remainingAttempts = 10 } = {}) => {
    try {
      const { user } = this.props;
      this.setState({ statusing: true });
      if (!has(user, 'signInUserSession.idToken.jwtToken')) {
        throw new Error('Authorization error');
      }
      const response = await axios({
        method: 'post',
        url: `${settings.apiUrl}/builder/status`,
        headers: {
          'Authorization': user.signInUserSession.idToken.jwtToken
        },
        data: {
          name: this.state.name
        }
      });
      this.setState({ statusing: false, status: response.data });
      // console.log('status:', response.data);

      if (response.data.status === 'BUILDING' && remainingAttempts >= 1) {
        this.setState({ checkingStatusContinuosly: true });
        this._timerUpdateStatus = setTimeout(async () => {
          await this.statusVUI({
            remainingAttempts: remainingAttempts - 1
          });
        }, 10000);
      } else {
        this.setState({ checkingStatusContinuosly: false });
      }
    } catch (err) {
      console.error('Status VUI Error:', err);
      this.setState({ statusing: false, error: err.message });
    }
  }

  deleteVUI = async () => {
    if (!this.props.newOne) {
      try {
        const { user } = this.props;
        this.setState({ deleting: true });
        if (!has(user, 'signInUserSession.idToken.jwtToken')) {
          throw new Error('Authorization error');
        }
        // const response = 
        await axios({
          method: 'post',
          url: `${settings.apiUrl}/builder/delete`,
          headers: {
            'Authorization': user.signInUserSession.idToken.jwtToken
          },
          data: {
            name: this.state.name
          }
        });
        // console.log('response:', response);
        this.setState({ deleting: false, vuiJson: {} });
      } catch (err) {
        console.error('Delete VUI Error:', err);
        this.setState({ deleting: false, error: err.message });
      }
    }
    await this.props.updateParent({ removeUuid: this.props.uuid });
  }

  testVUI = async () => {
    try {
      const { user } = this.props;
      this.setState(({ messages }) => {
        messages.push({ text: this.state.text, uuid: uuidv4() });
        return { testing: true, text: '', messages };
      });
      if (!has(user, 'signInUserSession.idToken.jwtToken')) {
        throw new Error('Authorization error');
      }
      const response = await axios({
        method: 'post',
        url: `${settings.apiUrl}/builder/test`,
        headers: {
          'Authorization': user.signInUserSession.idToken.jwtToken
        },
        data: {
          name: this.state.name,
          text: this.state.text,
        }
      });
      // console.log('response:', response);
      this.setState(({ messages }) => {
        messages.push(response.data);
        messages = messages.map(msg => {
          if (!has(msg, 'uuid')) { msg.uuid = uuidv4(); }
          return msg;
        })
        return { testing: false, messages };
      });
    } catch (err) {
      console.error('Test VUI Error:', err);
      this.setState({ testing: false, error: err.message });
    }
  }

  onJSONInputChange = (data) => {
    // console.log('onJSONInputChange data:', data);
    this.setState({ editorObject: data.jsObject });
  }

  build = () => {
    this.putVUI();
  }

  showHowToDefineVuiDoc = () => {
    console.log('showHowToDefineVuiDoc');
  }


  render = () => {
    const {
      visible, light, loading, uploading, statusing, deleting, testing,
      checkingStatusContinuosly
    } = this.state;

    return (
      <div>
        <VUICard
          name={this.state.name}
          status={this.state.status}
          newOne={this.state.newOne}
          statusLoader={statusing || checkingStatusContinuosly}
        />

        <Menu>
          <Menu.Item onClick={this.build}>Build VUI</Menu.Item>
          <Menu.Item onClick={this.handleTestOpen} disabled={this.state.newOne}>Test VUI</Menu.Item>
          <Dropdown text='Options' pointing className='link item'>
            <Dropdown.Menu>
              <Dropdown.Header>Options</Dropdown.Header>
              <Menu.Item onClick={this.statusVUI}>Check Status</Menu.Item>
              <Dropdown.Divider />
              <Menu.Item disabled>Save as file...</Menu.Item>
              <Dropdown.Divider />
              <Menu.Item onClick={this.showResetConfirm}>Reset</Menu.Item>
              <Dropdown.Item onClick={this.showDeleteConfirm}>Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/*
          <Menu.Item onClick={this.handleSchemaToggle}>
            Dark/Light
          </Menu.Item>
          */}

          <Menu.Menu position='right'>
            <Menu.Item onClick={this.handleSidebarToggle}>
              {visible ? 'Hide Help' : 'Help'}
            </Menu.Item>
           </Menu.Menu>
        </Menu>

        { this.state.error ? (
          <Message
            error
            header='Error'
            content={this.state.error}
          />
        ) : (
          <div />
        )}

        <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            animation='overlay'
            icon='labeled'
            direction='right'
            inverted
            vertical
            visible={visible}
            width='thin'
          >
            <Menu.Item as='a' header>Documentation</Menu.Item>
            <Menu.Item as='p' onClick={this.showHowToDefineVuiDoc}>
              <Link to="/docs" target="_blank">
                How to define VUI
              </Link>
          </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher>
            <Segment basic>
              <Dimmer active={loading}>
                <Loader>Loading</Loader>
              </Dimmer>
              <Dimmer active={uploading}>
                <Loader>Uploading</Loader>
              </Dimmer>
              <Dimmer active={deleting}>
                <Loader>Deleting</Loader>
              </Dimmer>

              <Header as='h4'>Voice User Interface Code</Header>

              <div style={{ maxWidth: "1400px", maxHeight: "100%" }}>
                <JSONInput
                  placeholder={this.state.vuiJson}
                  theme={
                    light ? 'light_mitsuketa_tribute' : 'dark_vscode_tribute'
                  }
                  locale={locale}
                  width='670px'
                  height='550px'
                  onChange={this.onJSONInputChange}
                />
              </div>

            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>

        <Confirm
          open={this.state.openedDeleteConfirm}
          confirmButton='Delete'
          onCancel={this.handleDeleteCancel}
          onConfirm={this.handleDeleteConfirm}
          header='Delete Confirmation'
          content={'Are you sure you want to delete the "' + this.state.name + '" VUI?'}
        />

        <Confirm
          open={this.state.openedResetConfirm}
          confirmButton='Reset'
          onCancel={this.handleResetCancel}
          onConfirm={this.handleResetConfirm}
          header='Reset Confirmation'
          content={'Are you sure you want to reset the "' + this.state.name + '" VUI?'}
        />

        <Modal
          open={this.state.openedTestModal}
          onClose={this.handleTestClose}
          closeIcon
        >
          <Modal.Header>Test VUI</Modal.Header>
          <Modal.Content>
          </Modal.Content>
          <Modal.Content scrolling>
            <List divided relaxed>
              <List.Header as='h4'>
                Conversation
              </List.Header>
              {
                this.state.messages.map(msg => {
                  if (has(msg, 'text')) {
                    return (
                      <List.Item key={'comment-' + msg.uuid}>
                        <List.Icon name='user' size='large' verticalAlign='middle' />
                        <List.Content>
                          <List.Header as='a'>{this.props.user.username}</List.Header>
                          <List.Description>
                            {msg.text}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    )
                  } else {
                    return (
                      <List.Item key={'comment-' + msg.uuid}>
                        <List.Icon name='bullhorn' size='large' verticalAlign='middle' />
                        <List.Content>
                          <List.Header as='a'>{this.state.name}</List.Header>
                          <List.Description>
                            <Label as='a' size='mini' basic pointing='right'>
                              <Icon name='crosshairs' />&nbsp;
                              {msg.intentName}
                            </Label>
                            {msg.message}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    )
                  }
                })
              }
            </List>
          </Modal.Content>
          <Modal.Actions>
            <Segment>
              <Form>
                <Input fluid
                  loading={testing}
                  icon='user'
                  iconPosition='left'
                  action={{
                    color: 'teal',
                    labelPosition: 'right',
                    icon: 'send',
                    content: 'Send',
                    onClick: this.testVUI
                  }}
                  value={this.state.text}
                  onChange={this.handleInput}
                />
              </Form>
            </Segment>

            <Button onClick={this.handleTestClose}>
              <Icon name='close' />Close
            </Button>
          </Modal.Actions>
        </Modal>

      </div>
    );
  }
}

export default VUIEditor;
