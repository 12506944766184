import React, { Component } from 'react';
import {
  // Table,
  Icon,
  // Button,
  Header,
  Segment,
  List,
  Message,
} from 'semantic-ui-react';

import Markdown from 'react-markdown';
import { AccountLayout } from './components';

function List1(props) {
  return <List>{props.children}</List>;
}

function ListItem1(props) {
  return <List.Item icon='dot circle' content={props.children} />;
}

class Docs extends Component {
  constructor(props, context) {
      super(props, context);

      this.ContentMarkdown = `
## VUI Specification

Voice User Interface (VUI) can be created with JSON-specification.

### VUI Schema

\`\`\`
{
  name: 'STRING_VALUE', /* required */

  description: 'STRING_VALUE',
  idleSessionTTLInSeconds: 0,
  voiceId: 'STRING_VALUE',
  childDirected: true || false,
  locale: en-US | en-GB | de-DE,

  abortStatement: {
    messages: [ /* required */
      {
        content: 'STRING_VALUE', /* required */
        contentType: PlainText | SSML | CustomPayload, /* required */
        groupNumber: 0
      },
      /* more items */
    ],
    responseCard: 'STRING_VALUE'
  },

  clarificationPrompt: {
    maxAttempts: 0, /* required */
    messages: [ /* required */
      {
        content: 'STRING_VALUE', /* required */
        contentType: PlainText | SSML | CustomPayload, /* required */
        groupNumber: 0
      },
      /* more items */
    ],
    responseCard: 'STRING_VALUE'
  },

  intents: [ /* required */
    {
      name: 'STRING_VALUE', /* required */
      description: 'STRING_VALUE',
      answer: 'STRING_VALUE', /* required */
      sampleUtterances: [
        'STRING_VALUE',
        /* more items */
      ],
      slots: [
        {
          name: 'STRING_VALUE', /* required */
          slotConstraint: Required | Optional, /* required */
          description: 'STRING_VALUE',
          priority: 0,
          responseCard: 'STRING_VALUE',
          sampleUtterances: [
            'STRING_VALUE',
            /* more items */
          ],
          slotType: 'STRING_VALUE',
          valueElicitationPrompt: {
            maxAttempts: 0, /* required */
            messages: [ /* required */
              {
                content: 'STRING_VALUE', /* required */
                contentType: PlainText | SSML | CustomPayload, /* required */
                groupNumber: 0
              },
              /* more items */
            ],
            responseCard: 'STRING_VALUE'
          }
        },
        /* more items */
      ],
      rejectionStatement: {
        messages: [ /* required */
          {
            content: 'STRING_VALUE', /* required */
            contentType: PlainText | SSML | CustomPayload, /* required */
            groupNumber: 0
          },
          /* more items */
        ],
        responseCard: 'STRING_VALUE'
      },
      conclusionStatement: {
        messages: [ /* required */
          {
            content: 'STRING_VALUE', /* required */
            contentType: PlainText | SSML | CustomPayload, /* required */
            groupNumber: 0
          },
          /* more items */
        ],
        responseCard: 'STRING_VALUE'
      },
      confirmationPrompt: {
        maxAttempts: 0, /* required */
        messages: [ /* required */
          {
            content: 'STRING_VALUE', /* required */
            contentType: PlainText | SSML | CustomPayload, /* required */
            groupNumber: 0
          },
          /* more items */
        ],
        responseCard: 'STRING_VALUE'
      },
      followUpPrompt: {
        prompt: { /* required */
          maxAttempts: 0, /* required */
          messages: [ /* required */
            {
              content: 'STRING_VALUE', /* required */
              contentType: PlainText | SSML | CustomPayload, /* required */
              groupNumber: 0
            },
            /* more items */
          ],
          responseCard: 'STRING_VALUE'
        },
        rejectionStatement: { /* required */
          messages: [ /* required */
            {
              content: 'STRING_VALUE', /* required */
              contentType: PlainText | SSML | CustomPayload, /* required */
              groupNumber: 0
            },
            /* more items */
          ],
          responseCard: 'STRING_VALUE'
        }
      },
    },
    /* more items */
  ],

  slotTypes: [
    {
      name: 'STRING_VALUE', /* required */
      description: 'STRING_VALUE',
      enumerationValues: [
        {
          value: 'STRING_VALUE', /* required */
          synonyms: [
            'STRING_VALUE',
            /* more items */
          ]
        },
        /* more items */
      ],
      valueSelectionStrategy: ORIGINAL_VALUE | TOP_RESOLUTION
    },
  ]
}
\`\`\`

### Main Parameters

* \`name: (String)\`

The name of the voice user interface (VUI). The name is not case sensitive.

* \`description: (String)\`

A description of the VUI.

* \`clarificationPrompt: (Map|String)\`

When Vuics doesn't understand the user's intent, it uses this message to get clarification. To specify how many times Vuics should repeate the clarification prompt, use the maxAttempts field. If Vuics still doesn't understand, it sends the message in the \`abortStatement\` field. When you create a clarification prompt, make sure that it suggests the correct response from the user.

* \`messages: (Array<Map>.Required)\`

An array of objects, each of which provides a message string and its type. You can specify the message string in plain text or in Speech Synthesis Markup Language (SSML).

* \`contentType: (String.Required)\`

The content type of the message string.

Possible values include:
"PlainText"
"SSML"
"CustomPayload"

* \`content: (String.Required)\`

The text of the message.

* \`groupNumber: (Integer)\`

Identifies the message group that the message belongs to. When a group is assigned to a message, Vuics returns one message from each group in the response.

* \`maxAttempts: (Integer.Required)\`

The number of times to prompt the user for information.

* \`responseCard: (String)\`

A response card. Vuics uses this prompt at runtime, in the PostText API response. It substitutes session attributes and slot values for placeholders in the response card.

* \`abortStatement: (Map)\`

When Vuics can't understand the user's input in context, it tries to elicit the information a few times. After that, Vuics sends the message defined in \`abortStatement\` to the user, and then aborts the conversation. To set the number of retries, use the \`valueElicitationPrompt\` field for the slot type.

* \`messages: (Array<Map>.Required)\`

A collection of message objects.

* \`contentType: (String.Required)\`

The content type of the message string.

Possible values include:
"PlainText"
"SSML"
"CustomPayload"

* \`content: (String.Required)\`

The text of the message.

* \`groupNumber: (Integer)\`

Identifies the message group that the message belongs to. When a group is assigned to a message, Vuics returns one message from each group in the response.

* \`responseCard: (String)\`

At runtime, if the client is using the PostText API, Vuics includes the response card in the response. It substitutes all of the session attributes and slot values for placeholders in the response card.

* \`idleSessionTTLInSeconds: (Integer)\`

The maximum time in seconds that Vuics retains the data gathered in a conversation.

A user interaction session remains active for the amount of time specified. If no conversation occurs during this time, the session expires and Vuics deletes any data provided before the timeout.

If you don't include the \`idleSessionTTLInSeconds\` element in a request, Vuics uses the default value. This is also true if the request replaces an existing VUI.

The default is 300 seconds (5 minutes).

* \`voiceId: (String)\`

The voice ID that you want Vuics to use for voice interactions with the user. The locale configured for the voice must match the locale of the VUI.

Available voice ids (en-US): Joey, Justin, Matthew, Ivy, Joanna, Kendra, Kimberly, Salli.

* \`locale: (String)\`

Specifies the target locale for the VUI. Any intent used in the VUI must be compatible with the locale of the VUI.

The default is en-US.

Possible values include:
"en-US"
"en-GB"
"de-DE"

* \`childDirected: (Boolean)\`

For each Vuics VUI, you must specify whether your use of Vuics is related to a website, program, or other application that is directed or targeted, in whole or in part, to children under age 13 and subject to the Children's Online Privacy Protection Act (COPPA) by specifying true or false in the childDirected field. By specifying true in the childDirected field, you confirm that your use of Vuics is related to a website, program, or other application that is directed or targeted, in whole or in part, to children under age 13 and subject to COPPA. By specifying false in the childDirected field, you confirm that your use of Vuics is not related to a website, program, or other application that is directed or targeted, in whole or in part, to children under age 13 and subject to COPPA. You may not specify a default value for the childDirected field that does not accurately reflect whether your use of Vuics is related to a website, program, or other application that is directed or targeted, in whole or in part, to children under age 13 and subject to COPPA.

If your use of Vuics relates to a website, program, or other application that is directed in whole or in part, to children under age 13, you must obtain any required verifiable parental consent under COPPA.

### Intents Parameters

* \`intents: (Array<Map>.Required)\`

Creates intents to define the interaction between the user and your VUI.

To create an intent or replace an existing intent, you must provide the following:
intent name,
sample utterances,
slot types for the information that your VUI will request from the user.

You can specify other optional information in the request, such as:
a confirmation prompt to ask the user to confirm an intent;
a conclusion statement to send to the user after the intent has been fulfilled;
a follow-up prompt that asks the user for additional activity.

* \`name: (String)\`

The name of the intent. The name is not case sensitive.

* \`description: (String)\`

A description of the intent.

* \`answer: (String)\`

An answer that VUI returns on the intent.

* \`slots: (Array<Map>)\`

An array of intent slots. At runtime, Vuics elicits required slot values from the user using prompts defined in the slots.

* \`name: (String.Requried)\`

The name of the slot.

* \`description: (String)\`

A description of the slot.

* \`slotConstraint: (String.Required)\`

Specifies whether the slot is required or optional.

Possible values include:
"Required"
"Optional"

* \`slotType: (String)\`

The type of the slot, either a custom slot type that you defined or one of the built-in slot types.

* \`slotTypeVersion: (String)\`

The version of the slot type.

* \`valueElicitationPrompt: (Map)\`

The prompt that Vuics uses to elicit the slot value from the user.

* \`messages: (Array<Map>.Required)\`

An array of objects, each of which provides a message string and its type. You can specify the message string in plain text or in Speech Synthesis Markup Language (SSML).

* \`contentType: (String.Required)\`

The content type of the message string.

Possible values include:
"PlainText"
"SSML"
"CustomPayload"

* \`content: (String.Required)\`

The text of the message.

* \`groupNumber: (Integer)\`

Identifies the message group that the message belongs to. When a group is assigned to a message, Vuics returns one message from each group in the response.

* \`maxAttempts: (Integer.Required)\`

The number of times to prompt the user for information.

* \`responseCard: (String)\`

A response card. Vuics uses this prompt at runtime, in the PostText API response. It substitutes session attributes and slot values for placeholders in the response card.

* \`priority: (Integer)\`

Directs Vuics the order in which to elicit this slot value from the user. For example, if the intent has two slots with priorities 1 and 2, Vuics first elicits a value for the slot with priority 1.

If multiple slots share the same priority, the order in which Vuics elicits values is arbitrary.

* \`sampleUtterances: (Array<String>)\`

If you know a specific pattern with which users might respond to an Vuics request for a slot value, you can provide those utterances to improve accuracy. This is optional. In most cases, Vuics is capable of understanding user utterances.

* \`responseCard: (String)\`

A set of possible responses for the slot type used by text-based clients. A user chooses an option from the response card, instead of using text to reply.

* \`sampleUtterances: (Array<String>)\`

An array of utterances (strings) that a user might say to signal the intent.

In each utterance, a slot name is enclosed in curly braces.

* \`confirmationPrompt: (Map.Required)\`

Prompts the user to confirm the intent. This question should have a yes or no answer.

Vuics uses this prompt to ensure that the user acknowledges that the intent is ready for fulfillment. For some intents, you might want to confirm that the order is correct before placing it. For other intents, such as intents that simply respond to user questions, you might not need to ask the user for confirmation before providing the information.

Note: You you must provide both the rejectionStatement and the confirmationPrompt, or neither.

* \`messages: (Array<Map>.Required)\`

An array of objects, each of which provides a message string and its type. You can specify the message string in plain text or in Speech Synthesis Markup Language (SSML).

* \`contentType: (String.Required)\`

The content type of the message string.

Possible values include:
"PlainText"
"SSML"
"CustomPayload"

* \`content: (String.Required)\`

The text of the message.

* \`groupNumber: (Integer)\`

Identifies the message group that the message belongs to. When a group is assigned to a message, Vuics returns one message from each group in the response.

* \`maxAttempts: (Integer.Required)\`

The number of times to prompt the user for information.

* \`responseCard: (String)\`

A response card. Vuics uses this prompt at runtime, in the PostText API response. It substitutes session attributes and slot values for placeholders in the response card.

* \`rejectionStatement: (Map)\`

When the user answers "no" to the question defined in confirmationPrompt, Vuics responds with this statement to acknowledge that the intent was canceled.

Note: You must provide both the rejectionStatement and the confirmationPrompt, or neither.

* \`messages: (Array<Map>.Required)\`

A collection of message objects.

* \`contentType: (String.Required)\`

The content type of the message string.

Possible values include:
"PlainText"
"SSML"
"CustomPayload"

* \`content: (String.Required)\`

The text of the message.

* \`groupNumber: (Integer)\`

Identifies the message group that the message belongs to. When a group is assigned to a message, Vuics returns one message from each group in the response.

* \`responseCard: (String)\`

At runtime, if the client is using the PostText API, Vuics includes the response card in the response. It substitutes all of the session attributes and slot values for placeholders in the response card.

* \`followUpPrompt: (Map)\`

Vuics uses this prompt to solicit additional activity after fulfilling an intent.

The action that Vuics takes depends on the user's response, as follows:

If the user says "Yes" it responds with the clarification prompt that is configured for the VUI.
if the user says "Yes" and continues with an utterance that triggers an intent it starts a conversation for the intent.
If the user says "No" it responds with the rejection statement configured for the the follow-up prompt.
If it doesn't recognize the utterance it repeats the follow-up prompt again.
The followUpPrompt field and the conclusionStatement field are mutually exclusive. You can specify only one.

* \`prompt: (Map.Required)\`

Prompts for information from the user.

* \`messages: (Array<Map>.Required)\`

An array of objects, each of which provides a message string and its type. You can specify the message string in plain text or in Speech Synthesis Markup Language (SSML).

* \`contentType: (String)\`

The content type of the message string.

Possible values include:
"PlainText"
"SSML"
"CustomPayload"

* \`content: (String.Required)\`

The text of the message.

* \`groupNumber: (Integer)\`

Identifies the message group that the message belongs to. When a group is assigned to a message, Vuics returns one message from each group in the response.

* \`maxAttempts: (Integer.Required)\`

The number of times to prompt the user for information.

* \`responseCard: (String)\`

A response card. Vuics uses this prompt at runtime, in the PostText API response. It substitutes session attributes and slot values for placeholders in the response card.

* \`rejectionStatement: (Map.Required)\`

If the user answers "no" to the question defined in the prompt field, Vuics responds with this statement to acknowledge that the intent was canceled.

* \`messages: (Array<Map>.Required)\`

A collection of message objects.

* \`contentType: (String.Required)\`

The content type of the message string.

Possible values include:
"PlainText"
"SSML"
"CustomPayload"

* \`content: (String.Required)\`

The text of the message.

* \`groupNumber: (Integer)\`

Identifies the message group that the message belongs to. When a group is assigned to a message, Vuics returns one message from each group in the response.

* \`responseCard: (String)\`

At runtime, if the client is using the PostText API, Vuics includes the response card in the response. It substitutes all of the session attributes and slot values for placeholders in the response card.

* \`conclusionStatement: (Map)\`

The statement that you want Vuics to convey to the user after the intent is successfully fulfilled by the Lambda function.

This element is relevant only if you provide a Lambda function in the fulfillmentActivity. If you return the intent to the client application, you can't specify this element.

Note: The followUpPrompt and conclusionStatement are mutually exclusive. You can specify only one.

* \`messages: (Array<Map>.Requierd)\`

A collection of message objects.

* \`contentType: (String.Required)\`

The content type of the message string.

Possible values include:
"PlainText"
"SSML"
"CustomPayload"

* \`content: (String.Required)\`

The text of the message.

* \`groupNumber: (Integer)\`

Identifies the message group that the message belongs to. When a group is assigned to a message, Vuics returns one message from each group in the response.

* \`responseCard: (String)\`

At runtime, if the client is using the PostText API, Vuics includes the response card in the response. It substitutes all of the session attributes and slot values for placeholders in the response card.

### SlotTypes Parameters

* \`slotTypes: (Array<Map>.Required)\`

Creates a custom slot type or replaces an existing custom slot type.

To create a custom slot type, specify a name for the slot type and a set of enumeration values, which are the values that a slot of this type can assume.

* \`name: (String)\`

The name of the slot type. The name is not case sensitive.

* \`description: (String)\`

A description of the slot type.

* \`enumerationValues: (Array<Map>)\`

A list of EnumerationValue objects that defines the values that the slot type can take. Each value can have a list of synonyms, which are additional values that help train the machine learning model about the values that it resolves for a slot.

When Vuics resolves a slot value, it generates a resolution list that contains up to five possible values for the slot. The valueSelectionStrategy field indicates the option to use.

* \`value: (String.Required)\`

The value of the slot type.

* \`synonyms: (Array<String>)\`

Additional values related to the slot type value.

* \`valueSelectionStrategy: (String)\`

Determines the slot resolution strategy that Vuics uses to return slot type values. The field can be set to one of the following values:

ORIGINAL_VALUE - Returns the value entered by the user, if the user value is similar to the slot value.
TOP_RESOLUTION - If there is a resolution list for the slot, return the first value in the resolution list as the slot type value. If there is no resolution list, null is returned.
If you don't specify the valueSelectionStrategy, the default is ORIGINAL_VALUE.

Possible values include:
"ORIGINAL_VALUE"
"TOP_RESOLUTION"

`;
  }

  render = () => (
    <AccountLayout>
      <Segment>
        <Message attached='top' floating>
          <Header as='h2'>
            <Icon link name='file alternate outline' />
            <Header.Content>
              Vuics Documentation
              <Header.Subheader>Read the documentation about Voice User Interfaces</Header.Subheader>
            </Header.Content>
          </Header>
        </Message>
        <br/>

        <p>
          The documentation describes voice user interface (VUI) schema
          and parameters.
        </p>

        <Markdown
          escapeHtml={true}
          source={this.ContentMarkdown}
          renderers={{
            list: List1,
            listItem: ListItem1,
          }}
        />

        <br/>
        <Message attached='bottom' warning>
          <Icon name='help' />
          If you would like us to help you, just contact us.
        </Message>
      </Segment>
    </AccountLayout>
  )
}

export default Docs
