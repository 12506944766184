import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Analytics } from 'aws-amplify';
import { Link, withRouter } from 'react-router-dom';

import {
  Button,
  Container,
  Divider,
  // Form,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
  Loader,
  Radio
} from 'semantic-ui-react';

import {
  Vuics,
  initVuics,
  // ButtonDefault,
  Oscilloscope,
  Consumer,
  Synthesizer,
  Recognizer
} from '@vuics/vui-react'

import ImgDashboard from './img/dashboard.png';
import ImgBuilder from './img/builder.png';
import ImgAnalytics from './img/analytics.png';
import ImgDocs from './img/docs.png';
import ImgCli from './img/cli.png';
import ImgVuiReact from './img/vui-react.png';
// import ImgVuistores from './img/vuistores.png';
import ImgHelloworld from './img/helloworld.png';
import ImgOrdercoffee from './img/ordercoffee.png';
import ImgWebsite from './img/website.png';

// console.log('apiKey:', process.env.REACT_APP_VUICS_API_KEY)
initVuics({
  apiKey: process.env.REACT_APP_VUICS_API_KEY
})

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
* such things.
*/

// const onContactUsClick = () => {
//   Analytics.record({ name: 'contactusButtonClick' })
// }

const personalArtemClick = () => {
  Analytics.record({ name: 'personalArtemClick' })
}

const personalAlexClick = () => {
  Analytics.record({ name: 'personalAlexClick' })
}

const seeExampleClick = () => {
  Analytics.record({ name: 'seeExampleClick' })
}

const contactusFooterClick = () => {
  Analytics.record({ name: 'contactusFooterClick' })
}

const HomepageHeading = withRouter(class _HomepageHeading extends Component {
  static propTypes = {
    mobile: PropTypes.bool,
    history: PropTypes.object.isRequired
  }

  state = {
    recognizing: false
  };

  onData = ({ intentName }) => {
    console.log('intentName:', intentName)
    if (this.state.recognizing) {
      Recognizer.resume();
    }

    switch (intentName) {
      case 'ContactUs':
        console.log('ContactUs');
        window.location = 'mailto:admin@vuics.com';
        break;
      case 'SignIn':
        console.log('SignIn');
        this.props.history.push('/dashboard');
        break;
      case 'SignUp':
        console.log('SignUp');
        this.props.history.push('/dashboard');
        break;
      case 'SeeExample':
        console.log('SeeExample');
        window.open('https://vuistores.vuics.com/');
        break;
      default:
    }
  }

  savedOnClick = () => {};

  wakeUp = () => { console.log('wakeUp'); this.savedOnClick(); }

  componentDidMount = () => {
    const onWakeWord = (greeting, vuiname) => {
      console.log('greeting:', greeting, ', vuiname:', vuiname);
      Synthesizer.speak({ phrase: 'Listening' });
      setTimeout(this.wakeUp, 1000);
      Recognizer.abort(); // alternative way: Recognizer.pause();
    }

    const onSleepWord = (farewell, vuiname) => {
      console.log('farewell:', farewell, ', vuiname:', vuiname);
      Synthesizer.speak({ phrase: 'Talk to you soon' });
      Recognizer.abort();
      this.setState(() => ({ recognizing: false }));
    }

    Recognizer.addCommands({
      ':greeting :vuiname': {
        'regexp': /^(Hello|Hey|Hi) (Vuics|Voice|Voice Interface|Voice User Interface)$/,
        'callback': onWakeWord
      },
      'Click to Speak': onWakeWord,

      ':farewell :vuiname': {
        'regexp': /^(Goodbye|Bye|Bye-bye) (Vuics|Voice|Voice Interface|Voice User Interface)$/,
        'callback': onSleepWord
      }
    });
  }

  onRadioChange = (event, data) => {
    if (data.checked) {
      Recognizer.resume();
    } else {
      Recognizer.abort();
    }
    this.setState(() => ({ recognizing: data.checked }));
  }

  render = () => (
    <Vuics
      name='VuicsHome'
      onConversationData={this.onData}
      fillStyle='rgb(27,28,29)'
      lineWidth={2}
      strokeStyle='teal'
    >
      <Container text>
        <Oscilloscope
          canvasWrapperClassName='canvasWrapper'
          canvasClassName='canvas'
        />

        <div style={{ zIndex: 1, position: 'relative' }}>
          <Header
            as='h1'
            content='Vuics'
            inverted
            style={{
              fontSize: this.props.mobile ? '2em' : '4em',
              fontWeight: 'normal',
              marginBottom: 0,
              marginTop: this.props.mobile ? '1.5em' : '3em'
            }}
          />

          <Header
            as='h2'
            inverted
            style={{
              fontSize: this.props.mobile ? '1.5em' : '1.7em',
              fontWeight: 'normal',
              marginTop: this.props.mobile ? '0.5em' : '1.5em'
            }}
          >
            <Header.Subheader>
            <p style={{ fontSize: '1.33em' }}>
              Vuics is a Platform-as-a-Service that helps businesses and
              developers to build and serve Voice User Interfaces (VUI)
              for any websites and apps.
            </p>
            <p style={{ fontSize: '1.33em' }}>
              Vuics can provide VUI-integration services.
            </p>
            </Header.Subheader>
          </Header>
          <br />

          <div style={{ color: 'grey', margin: '1em' }}>
            <Radio toggle
              style={{ verticalAlign: 'middle' }}
              disabled={!Recognizer.isSupported()}
              checked={this.state.recognizing}
              onChange={this.onRadioChange}
            />
            &nbsp; &nbsp;
            {
              Recognizer.isSupported()
                ? ( <span>Wake-Word <i>"Hello Voice"</i></span>) :
                  (<span>No browser support of Wake-Word</span>)
            }
            &nbsp;
          </div>

          <Consumer>
            {
              ({ buttonRef, onClick, state, message }) => {
                this.savedOnClick = onClick

                return (
                  <Button
                    className='button'
                    onClick={this.wakeUp}
                    ref={buttonRef}
                    size='huge'
                    color='teal'
                  >
                    {
                      state === 'Passive'
                        ? 'Click to Speak 🎙️'
                        : state === 'Listening'
                          ? '🎤 Say a Phrase (e.g. "Help me")'
                          : state === 'Sending'
                            ? <Loader active inline='centered' />
                            : '🔊' + message
                    }
                  </Button>
                )
              }
            }
          </Consumer>
        </div>
      </Container>
    </Vuics>
  )
})

class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => {
    this.setState(() => ({ fixed: false }))
  }
  showFixedMenu = () => {
    this.setState(() => ({ fixed: true }))
  }

  render = () => {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <div id='Vuics' />
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 700, padding: '1em 0' }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item active>
                  <a href='#Vuics'>
                    <span role='img' aria-label='Vuics'>🦄  </span>
                    Vuics
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a href='#Paas'>
                    <span role='img' aria-label='PaaS'>🚀  </span>
                    PaaS
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a href='#Usage'>
                    <span role='img' aria-label='Usage'>🤖 </span>
                    Usage
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a href='#Examples'>
                    <span role='img' aria-label='Examples'>🛍 </span>
                    Examples
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a href='#API'>
                    <span role='img' aria-label='API'>👨🏻‍💻  </span>
                    API
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <Link to='/pricing'>
                    <span role='img' aria-label='Price tag'>🔖 </span>
                    Pricing
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to='/jobs'>
                    <span role='img' aria-label='Jobs'>🏦 </span>
                    Jobs
                  </Link>
                </Menu.Item>
                <Menu.Item position='right'>
                  <Button inverted={!fixed} style={{ marginLeft: '0.5em' }} color='teal'>
                    <Link to='/dashboard' textColor='white'>
                      <span role='img' aria-label='Login'>👉  </span>
                      <span style={{ color: 'white' }}>Sign In</span>
                      &nbsp;/&nbsp;
                      <span role='img' aria-label='Signup'>👋  </span>
                      <span style={{ color: 'white' }}>Sign Up</span>
                    </Link>
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node
}

class MobileContainer extends Component {
  state = {}

  handlePusherClick = () => {
    if (this.state.sidebarOpened) {
      this.setState(
        () => ({ sidebarOpened: false })
      )
    }
  }

  handleToggle = () => {
    this.setState(
      ({ sidebarOpened }) => ({
        sidebarOpened: !sidebarOpened
      })
    )
  }

  render = () => (
    <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation='uncover'
          inverted
          vertical
          visible={this.state.sidebarOpened}
        >
          <Menu.Item active>
            <a href='#Vuics'>
              <span role='img' aria-label='Vuics'>🦄  </span>
              Vuics
            </a>
          </Menu.Item>
          <Menu.Item>
            <a href='#Paas'>
              <span role='img' aria-label='Startup'>🚀  </span>
              PaaS
            </a>
          </Menu.Item>
          <Menu.Item active>
            <a href='#Usage'>
              <span role='img' aria-label='Usage'>🤖 </span>
              Usage
            </a>
          </Menu.Item>
          <Menu.Item>
            <a href='#Examples'>
              <span role='img' aria-label='Examples'>🛍 </span>
              Examples
            </a>
          </Menu.Item>
          <Menu.Item>
            <a href='#API'>
              <span role='img' aria-label='API'>👨🏻‍💻  </span>
              API
            </a>
          </Menu.Item>
          <Menu.Item>
            <Link to='/pricing'>
              <span role='img' aria-label='Price tag'>🔖 </span>
              Pricing
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to='/jobs'>
              <span role='img' aria-label='Jobs'>🏦 </span>
              Jobs
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to='/dashboard'>
              <span role='img' aria-label='Login'>👉  </span>
              Sign In
              &nbsp;/&nbsp;
              <span role='img' aria-label='Signup'>👋  </span>
              Sign Up
            </Link>
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher
          dimmed={this.state.sidebarOpened}
          onClick={this.handlePusherClick}
          style={{ minHeight: '100vh' }}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 350, padding: '1em 0em' }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Button inverted>
                    <Link to='/dashboard'>
                      <span role='img' aria-label='Login'>👉  </span>
                      Sign In
                      &nbsp;/&nbsp;
                      <span role='img' aria-label='Signup'>👋  </span>
                      Sign Up
                    </Link>
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {this.props.children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Responsive>
  )
}

MobileContainer.propTypes = {
  children: PropTypes.node
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node
}

const HomepageLayout = withRouter(({ history }) => (
  <ResponsiveContainer>
    <div id='Paas' />
    <Container text>
      <Segment style={{ marginTop: '5em' }}>
        <Header as='h3' style={{ fontSize: '2em' }}>
          <span role='img' aria-label='Rocket'>🚀 </span>
          Platform-as-a-Service
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Vuics is a Platform-as-a-Service that offers a simple way to build,
          integrate, and serve Voice User Interfaces (VUI) for
          any websites and apps.
          Vuics provides API to software engineers for the development
          of voice user interfaces.
        </p>
        <Header as='h4' style={{ fontSize: '1.5em' }}>
          <span role='img' aria-label='Satelite'>🛰 </span>
          Features & Technologies
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Vuics PaaS has a dashboard, VUI builder, VUI analytics, documentation,
          support, React.js integration component, command-line interface,
          and other features.
          {/*
          Vuics could provide creation of Voice User Interfaces through using
          Voice User Interface (or regular graphical UI).
          */}
        </p>
        <p style={{ fontSize: '1.33em' }}>
          Vuics API uses such technologies as Speech Recognition,
          Speech Synthesis, Natural Language Understanding and Processing,
          Artificial Intelligence, and others.
        </p>
      </Segment>

      <Segment>
        <Header as='h3' style={{ fontSize: '2em' }}>
          <span role='img' aria-label='Astronaut'>👨🏻‍🚀 </span>
          Integration Services
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          The Vuics offers services of VUI integration by our software engineers.
          We integrate Voice User Interfaces with Artificial Intelligence
          into any
          <ul>
            <li>websites,</li>
            <li>web apps,</li>
            <li>Internet-of-Things devices,</li>
            <li>mobile/desktop apps,</li>
            <li>operating systems,</li>
            <li>clouds,</li>
            <li>quantum computer apps,</li>
            <li>and more.</li>
          </ul>
        </p>
      </Segment>
    </Container>

    <Divider/>

    <Segment vertical>
      <Grid celled='internally' columns='equal' stackable>
        <Grid.Row>
          <Grid.Column textAlign='center'>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <a href='https://vuics.com/dashboard' target='_blank' rel='noopener noreferrer'>
                Dashboard
              </a>
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Allows using created Voice Interfaces.
            </p>
            <Image
              bordered rounded size='large' centered src={ImgDashboard}
              href='https://vuics.com/dashboard' target='_blank' rel='noopener noreferrer'
            />
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <a href='https://vuics.com/builder' target='_blank' rel='noopener noreferrer'>
                Builder
              </a>
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              You could develop your voice interface with the Builder.
            </p>
            <Image
              bordered rounded size='large' centered src={ImgBuilder}
              href='https://vuics.com/builder' target='_blank' rel='noopener noreferrer'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment vertical>
      <Grid celled='internally' columns='equal' stackable>
        <Grid.Row>
          <Grid.Column textAlign='center'>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <a href='https://vuics.com/analytics' target='_blank' rel='noopener noreferrer'>
                Analytics
              </a>
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Allows you to analyze and optimize your voice interfaces.
            </p>
            <Image
              bordered rounded size='large' centered src={ImgAnalytics}
              href='https://vuics.com/analytics' target='_blank' rel='noopener noreferrer'
            />
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <a href='https://vuics.com/docs' target='_blank' rel='noopener noreferrer'>
                Documentation
              </a>
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Describes how to create your voice interface from scratch.
            </p>
            <Image
              bordered rounded size='large' centered src={ImgDocs}
              href='https://vuics.com/docs' target='_blank' rel='noopener noreferrer'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <div id='Usage' />
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Container text>
        <Header as='h3' style={{ fontSize: '2em' }}>
          <span role='img' aria-label='Usage'>🤖 </span>
          Usage
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          The Vuics website has integrated Voice User Interface (VUI).
        </p>

        <p style={{ fontSize: '1.33em' }}>
          <span role='img' aria-label='Bell'>🛎 </span>
          Try it!
        </p>
        <p style={{ fontSize: '1em' }}>
          Click the <b>"Click to Speak"</b> button or
          simply say <i>"Hello Voice"</i> wake-word, and then:
        </p>

        <List bulleted>
          <List.Item>
            Get help with <i>"Help me"</i> voice command.
          </List.Item>
          <List.Item>
            Ask questions:
            <i>"What is Vuics?"</i>, <i>"What do you do?"</i>, or
            <i>"Tell me about your projects"</i>.
          </List.Item>
          <List.Item>
            Greet and farewell: <i>"Hello!"</i>,
            <i>"How are you?"</i> and <i>"Bye!"</i>.
          </List.Item>
          <List.Item>
            Sign in/up with <i>"Sign in"</i>, or <i>"Sign up"</i>.
          </List.Item>
          <List.Item>
            Learn about the example with <i>"See example"</i>.
          </List.Item>
          <List.Item>
            Contact us with the <i>"Contact Vuics"</i> command.
          </List.Item>
        </List>

        <p>
          If you want to disable using wake-word,
          say <i>"Goodbye Voice"</i> sleep-word.
        </p>

        <Divider />

        <div
          style={{
            position: 'relative',
            overflow: 'hidden',
            paddingTop: '56.25%'
          }}
        >
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 0
            }}
            src="https://www.youtube.com/embed/6hqKybupC0c"
            title="Vuics Demo"
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />

        </div>
      </Container>
    </Segment>

    <Container text>
      <Segment vertical style={{ marginTop: '4em' }}>
        <Header as='h3' style={{ fontSize: '2em' }} textAlign='center'>
          <span role='img' aria-label='Tophat'>🎩  </span>
          About
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          We integrate Voice User Interfaces with Artificial Intelligence
          into apps and websites.
          Vuics helps startups and companies, individual developers, and
          any other people to have their Voice User Interfaces.
        </p>

        <Header as='h3' style={{ fontSize: '2em' }} textAlign='center'>
          <span role='img' aria-label='World'>🌎  </span>
          Our mission
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          The Vuics has a mission to enable talking with any information in the World.
        </p>

        <Header as='h3' style={{ fontSize: '2em' }} textAlign='center'>
          <span role='img' aria-label='Help'>💞  </span>
          Solutions
        </Header>
        <List style={{ fontSize: '1.2em' }} divided relaxed selection animated>
          <List.Item>
            <span role='img' aria-label='Car'>🚗 </span>
            Allow drivers to talk to the websites during driving a car.
          </List.Item>
          <List.Item>
            <span role='img' aria-label='Runner'>🏃🏻‍ </span>
            Let walkers and runners use customer apps with voice control.
          </List.Item>
          <List.Item>
            <span role='img' aria-label='Disabled'>♿  </span>
            Enable blind, paralytics, and other disabled persons to control websites with voice.
          </List.Item>
          <List.Item>
            <span role='img' aria-label='Call'>📲 </span>
            The VUI of the website could call clients and ask them questions or share information automatically.
          </List.Item>
          <List.Item>
            <span role='img' aria-label='Speak'>🗣️ </span>
            Helps to non-native English speakers to practice English talking with voice bot.
          </List.Item>
          <List.Item>
            <span role='img' aria-label='Email'>👁️‍🗨️</span>
            Enable better speech understanding during webinars or video conferences with posting transcription as subtitles.
          </List.Item>
          <List.Item>
            <span role='img' aria-label='Phone'>☎️  </span>
            Allow people who use neither desktop computers nor even smartphones to talk with websites of our customers through phone lines.
          </List.Item>
          <List.Item>
            <span role='img' aria-label='Satellite'>🛰️  </span>
            And others.
          </List.Item>
        </List>
        <Button size='large' color='teal' basic>
          <a href='https://medium.com/@artemarakcheev/voice-user-interfaces-use-cases-and-solvable-problems-c02eb8b96831' target='_blank' rel='noopener noreferrer'>
            <span style={{ color: 'teal' }}>Read more</span>
          </a>
        </Button>
      </Segment>
    </Container>

    <div id='Examples' />
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Header as='h2' style={{ fontSize: '3em', marginBottom: '2em' }} textAlign='center'>
        <span role='img' aria-label='Examples'>🛍 </span>
        Examples
      </Header>

      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <Icon name='world' />
              Hello World
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Hello World is the most simple Voice User Interface (VUI)
              created with the Vuics platform.
              <br />
              <br />
              <Button size='huge' color='teal' basic onClick={seeExampleClick}>
                <a href='https://helloworld.vuics.com/' target='_blank' rel='noopener noreferrer'>
                  <span style={{ color: 'teal' }}>
                    <Icon name='external alternate' />
                    See Hello World Example
                  </span>
                </a>
              </Button>
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image
              bordered rounded size='large' src={ImgHelloworld}
              href='https://helloworld.vuics.com/' target='_blank' rel='noopener noreferrer'
            />
          </Grid.Column>
        </Grid.Row>

        <Divider/>

        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <Icon name='coffee' />
              Order Coffee
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Order Coffee is an advanced example of Voice User Interface
              (VUI) created with the Vuics platform.
              <br />
              <br />
              <Button size='huge' color='teal' basic onClick={seeExampleClick}>
                <a href='https://ordercoffee.vuics.com/' target='_blank' rel='noopener noreferrer'>
                  <span style={{ color: 'teal' }}>
                    <Icon name='external alternate' />
                    See Order Coffee Example
                  </span>
                </a>
              </Button>
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image
              bordered rounded size='large' src={ImgOrdercoffee}
              href='https://ordercoffee.vuics.com/' target='_blank' rel='noopener noreferrer'
            />
          </Grid.Column>
        </Grid.Row>

        <Divider/>
  {/*
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <Icon name='coffee' />
              Order Coffee
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Order Coffee is an advanced example of Voice User Interface
              (VUI) created with the Vuics platform.
              <br />
              <br />
              <Button size='huge' basic onClick={seeExampleClick}>
                <a href='https://ordercoffee.vuics.com/' target='_blank' rel='noopener noreferrer'>
                  <Icon name='external alternate' />
                  See Order Coffee Example
                </a>
              </Button>
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image bordered rounded size='large' src={ImgOrdercoffee} />
          </Grid.Column>
        </Grid.Row>
  */}

        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <Icon name='rocket' />
              Website Example
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              The website is an example of a Voice User Interface (VUI) integrated
              into a website. It enables voice navigation, talking to buttons,
              and filling out forms with voice.
              <br />
              <br />
              <Button size='huge' color='teal' basic onClick={seeExampleClick}>
                <a href='https://website.vuics.com/' target='_blank' rel='noopener noreferrer'>
                  <span style={{ color: 'teal' }}>
                    <Icon name='external alternate' />
                    See Website Example
                  </span>
                </a>
              </Button>
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image
              bordered rounded size='large' src={ImgWebsite}
              href='https://website.vuics.com/' target='_blank' rel='noopener noreferrer'
            />
          </Grid.Column>
        </Grid.Row>

        {/*
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <span role='img' aria-label='Cart'>🛒 </span>
              Order Flowers
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              E-commerce Voice Cart Assistant that allows to purchase flowers
              throug Voice User Interface.
              <br />
              <br />
              <Button size='huge' basic onClick={seeExampleClick}>
                <a href='https://vuistores.vuics.com/' target='_blank' rel='noopener noreferrer'>
                  <span role='img' aria-label='Cart'>🛒 </span>See example
                </a>
              </Button>
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image bordered rounded size='large' src={ImgVuistores} />
          </Grid.Column>
        </Grid.Row>
        */}

      </Grid>
    </Segment>

    <div id='API' />
    <Container text>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Header as='h2' style={{ fontSize: '3em' }} textAlign='center'>
          <span role='img' aria-label='API'>👨🏻‍💻  </span>
          Vuics API
        </Header>

        <p style={{ fontSize: '1.33em' }}>
          Vuics API allows you to build and serve Voice User Interfaces (VUI)
          for your website or application.
        </p>

        <p style={{ fontSize: '1.33em' }}>
          We provide:
          <List bulleted>
            <List.Item>
              Web VUI&nbsp;
              <a href='https://vuics.com/builder' target='_blank' rel='noopener noreferrer'>
                Builder
              </a>
              .
            </List.Item>
            <List.Item>
              <a href='https://www.npmjs.com/package/@vuics/cli' target='_blank' rel='noopener noreferrer'>
                <b>@vuics/cli</b>
              </a>&nbsp;
              CLI VUI Builder.
            </List.Item>
            <List.Item>
              <a href='https://www.npmjs.com/package/@vuics/vui-react' target='_blank' rel='noopener noreferrer'>
                <b>@vuics/vui-react</b>
              </a>
              &nbsp;VUI component for&nbsp;
              <a href='https://reactjs.org/' target='_blank' rel='noopener noreferrer'>
                React.js
              </a>&nbsp;
              web apps.
            </List.Item>
          </List>
        </p>

        <Header as='h3' style={{ fontSize: '2em' }} textAlign='center'>
          <span role='img' aria-label='Diamond'>💎 </span>
          Features
        </Header>
        <List bulleted>
          <List.Item>
            Vuics API for building and serving VUIs.
          </List.Item>
          <List.Item>
            Server-side Speech Synthesis and Speech Recognition with Vuics Speech API.
          </List.Item>
          <List.Item>
            Client-side Speech Synthesis and Speech Recognition with Web Speech API.
          </List.Item>
          <List.Item>
            Natural language understanding with Vuics API.
          </List.Item>
          <List.Item>
            Wake-word and sleep-word to enable/disable VUI with client-side speech recognition.
          </List.Item>
          <List.Item>
            Recording audio from the microphone.
          </List.Item>
          <List.Item>
            Default and custom Click-to-Speak buttons.
          </List.Item>
          <List.Item>
            Oscilloscope component to visualize recording.
          </List.Item>
          <List.Item>
            Triggering actions by VUI events.
          </List.Item>
          <List.Item>
            Examples.
          </List.Item>
          <List.Item>
            And others.
          </List.Item>
        </List>
      </Segment>
    </Container>

    <Divider/>

    <Segment vertical>
      <Grid celled='internally' columns='equal' stackable>
        <Grid.Row>
          <Grid.Column textAlign='center'>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <a href='https://www.npmjs.com/package/@vuics/vui-react' target='_blank' rel='noopener noreferrer'>
                @vuics/vui-react
              </a>
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Voice User Interface component for React.js web apps.
            </p>
            <Image
              bordered rounded size='large' centered src={ImgVuiReact}
              href='https://www.npmjs.com/package/@vuics/vui-react' target='_blank' rel='noopener noreferrer'
            />
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Header as='h3' style={{ fontSize: '2em' }}>
              <a href='https://www.npmjs.com/package/@vuics/cli' target='_blank' rel='noopener noreferrer'>
                @vuics/cli
              </a>
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Command Line Interface for building and testing
              Voice Interfaces.
            </p>
            <Image
              bordered rounded size='large' centered src={ImgCli}
              href='https://www.npmjs.com/package/@vuics/cli' target='_blank' rel='noopener noreferrer'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Container text>
      <Segment style={{ padding: '8em 0em' }} vertical>
        <Header as='h3' style={{ fontSize: '2em' }} textAlign='center'>
          <span role='img' aria-label='Postal Horn'>📯 </span>
          How to get started?
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Please, do the following steps to integrate VUI into your
          web application.
        </p>

        <List ordered style={{ fontSize: '1.33em' }} >
          <List.Item>
            <Link to='/dashboard'>
            <span role='img' aria-label='Signup'>👉  </span>
            <a href='https://vuics.com/dashboard' target='_blank' rel='noopener noreferrer'>
              Sign up and sign in.
            </a>
            </Link>
          </List.Item>
          <List.Item>
            <span role='img' aria-label='Building'>🏗️ </span>
            Build your voice interface in&nbsp;
            <a href='https://vuics.com/builder' target='_blank' rel='noopener noreferrer'>
              Builder
            </a>
            , or, alternatively, with the&nbsp;
            <a href='https://www.npmjs.com/package/@vuics/cli' target='_blank' rel='noopener noreferrer'>
              <b>@vuics/cli</b>
            </a>
            .
          </List.Item>
          <List.Item>
            <span role='img' aria-label='Key'>🔑 </span>
            Create a Vuics API Key in&nbsp;
            <a href='https://vuics.com/settings' target='_blank' rel='noopener noreferrer'>
              Settings
            </a>
            .
          </List.Item>
          <List.Item>
            <span role='img' aria-label='Installation'>📥 </span>
            Integrate the&nbsp;
            <a href='https://www.npmjs.com/package/@vuics/vui-react' target='_blank' rel='noopener noreferrer'>
              <b>@vuics/vui-react</b>
            </a>
            &nbsp;npm package into your website.
          </List.Item>
          <List.Item>
            <span role='img' aria-label='Rising hand'>🙋 </span>
            Feel free to ask us any questions. We would love to help with the integration.
          </List.Item>
        </List>

        <Header as='h3' style={{ fontSize: '2em' }} textAlign='center'>
          <span role='img' aria-label='Developer'>👨🏻‍🎨 👩🏻‍🔬 👨🏻‍🚀 </span>
          Do you develop for another platform?<br />
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Please,&nbsp;
          <a href='mailto:admin@vuics.com?subject=Request Vuics Support for my platfrom&body=Hi Vuics Team, I want to request adding Vuics Support for my platform. <Describe platform>. Thanks.'>
            contact us
          </a>
          &nbsp;and we would like to add support for your platform.
        </p>

        <Header as='h3' style={{ fontSize: '2em' }} textAlign='center'>
          <span role='img' aria-label='Bride'>👰🏻 </span>
          Are not you a developer?
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Please,&nbsp;
          <a href='mailto:admin@vuics.com?subject=Request VUI Integration&body=Hi Vuics Team, I want to request VUI Integration into my website/app. <Describe your website or app>. Thanks.'>
            contact us
          </a>
          &nbsp;and we will integrate VUI into your website/app.
        </p>
      </Segment>
    </Container>

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='About' />
              <List link inverted>
                <List.Item onClick={contactusFooterClick}>
                  <a href='mailto:admin@vuics.com'>
                    <span role='img' aria-label='Email'>📧  </span>
                    Contact Us
                  </a>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={6}>
              <Header as='h4' inverted>
                    Vuics
              </Header>
              <List link inverted>
                <List.Item>
                  <span role='img' aria-label='Thanks'>🙏 </span>
                      Let people talk to your website.
                </List.Item>
                {/*
                <br />
                <br />
                <br />
                <br />
                <List.Item>
                  <span role='img' aria-label='Beating heart'>💝 </span>
                  <i>Vuics loves you.</i>
                </List.Item>
                */}
                <br />
                <br />
                <br />
                <p>
                      © 2018-2021 Vuics. All rights reserved.
                </p>
              </List>
            </Grid.Column>
            <Grid.Column width={5}>
              <Header as='h4' inverted>
                    &nbsp;
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
))

class Home extends Component {
        componentDidMount = () => {
          Analytics.record({ name: 'homeVisit' })
        }

        render = () => (
          <HomepageLayout />
        )
}

export default Home;
