import React from 'react';

import {
BrowserRouter as Router,
Route,
Switch
} from 'react-router-dom';

import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';

import Home from './Home';
import Pricing from './Pricing';
import Jobs from './Jobs';
import Docs from './Docs';

import Dashboard from './Dashboard';
import Builder from './Builder';
import Analytics from './Analytics';
import Profile from './Profile';
import Settings from './Settings';
// import Test from './Test';

Amplify.configure(aws_exports);

// https://aws-amplify.github.io/docs/js/api#manual-configuration
// https://aws-amplify.github.io/docs/js/api#using-a-graphql-server
// https://aws-amplify.github.io/docs/js/api#signing-request-with-iam
// Amplify.configure({
//   // sing AWS_IAM
//   // "aws_appsync_graphqlEndpoint": "https://tnrdmbn6xbahtcl4e2ybf7pbx4.appsync-api.us-west-2.amazonaws.com/graphql",
//   // "aws_appsync_region": "us-west-2",
//   // 'aws_appsync_authenticationType': 'AWS_IAM',

//   API: {
//     graphql_endpoint: 'https://api.vuics.com/graphql',
//     graphql_endpoint_iam_region: 'us-west-2'
//   }
// });

// Optionally add Debug Logging
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

const NoMatch = () => (
  <div>
    <h2>Error</h2>
    <p>No such route</p>
  </div>
)

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route path="/pricing" component={Pricing}/>
      <Route path="/jobs" component={Jobs}/>
      <Route path="/docs" component={Docs}/>

      {/* Auth required */}
      <Route path="/dashboard" component={Dashboard}/>
      <Route path="/builder" component={Builder}/>
      <Route path="/analytics" component={Analytics}/>
      <Route path="/profile" component={Profile}/>
      <Route path="/settings" component={Settings}/>

      {/* Test */}
      {/*
      <Route path="/test" component={Test}/>
      */}
      <Route component={NoMatch}/>
    </Switch>
  </Router>
)

export default App;
