// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:83bb4321-9bbf-4e11-95a0-91d06fa45d9b",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_C64Y7xYJx",
    "aws_user_pools_web_client_id": "4coh0ilu4a67lqpk6ht2hh9bq8",
    "aws_content_delivery_bucket": "vuics.com",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://djgug5ygnlkak.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://tnrdmbn6xbahtcl4e2ybf7pbx4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-bfe6wdhxi5bjzmazmzrjy3m34m",
    "aws_mobile_analytics_app_id": "091a73e4630e4b86b173b0df2f6aa510",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
