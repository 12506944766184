import React, { Component } from 'react'
import {
  Button,
  Header,
  Segment,
  List,
  Container,
  Message,
  Icon,
} from 'semantic-ui-react'

class Jobs extends Component {
  render = () => (
    <Container text>
      <br/>
      <Message attached='top' floating>
        <Header as='h2'>
          <Icon link name='briefcase' />
          <Header.Content>
            Vuics Jobs
            <Header.Subheader>Apply to a job</Header.Subheader>
          </Header.Content>
        </Header>
      </Message>

      <div id='marketer' />
      <Segment>
        <Header as='h3' style={{ fontSize: '1.5em' }}>
          Marketing executive
        </Header>

        <p>
          Marketing executives aim to maximise profits through developing
          sales strategies that match customer requirements and by promoting
          our services.
        </p>

        <Header as='h3' style={{ fontSize: '1.1em' }}>
          Key responsibilities
        </Header>
        <List bulleted>
          <List.Item>
            Overseeing and developing marketing campaigns.
          </List.Item>
          <List.Item>
            Conducting research and analyzing data to identify and define audiences.
          </List.Item>
          <List.Item>
            Devising and presenting ideas and strategies.
          </List.Item>
          <List.Item>
            Promotional activities.
          </List.Item>
          <List.Item>
            Compiling and distributing financial and statistical information.
          </List.Item>
          <List.Item>
            Writing and proofreading creative copy.
          </List.Item>
          <List.Item>
            Maintaining websites and looking at data analytics.
          </List.Item>
          <List.Item>
            Organizing events and product exhibitions.
          </List.Item>
          <List.Item>
            Updating databases and using a customer relationship management (CRM) system.
          </List.Item>
          <List.Item>
            Coordinating internal marketing and an organisztion’s culture.
          </List.Item>
          <List.Item>
            Monitoring performance.
          </List.Item>
          <List.Item>
            Managing campaigns on social media.
          </List.Item>
        </List>

        <Header as='h3' style={{ fontSize: '1.1em' }}>
          How to apply?
        </Header>
        <p>
          Please, send us your resume.
          <br/>
          <br/>
          <Button color='teal'>
            <a style={{ color: 'white' }} href="mailto:admin@vuics.com?subject=Vuics Marketing Executive Job&body=Hi Vuics Team, I want to apply on Vuics Marketing Executive Job. <Attach resume>. Thanks.">
              Apply to the Job
            </a>
          </Button>
        </p>
      </Segment>

      <div id='integrator' />
      <Segment>
        <Header as='h3' style={{ fontSize: '1.5em' }}>
          Software Engineer / Voice User Interface Integrator
        </Header>

        <p>
          We are looking for a Software Engineer who could help us with Vuics integration.
        </p>

        <Header as='h3' style={{ fontSize: '1.1em' }}>
          Tasks
        </Header>
        <List bulleted>
          <List.Item>
            Integrate voice user interfaces into websites and apps.
          </List.Item>
          <List.Item>
            It is your advantage if you can help us to work on Accounts,
            Dashboard, Analytics, Notification System, and API.
          </List.Item>
        </List>

        <Header as='h3' style={{ fontSize: '1.1em' }}>
          Skills required
        </Header>
        <List bulleted>
          <List.Item>
            JavaScript
          </List.Item>
          <List.Item>
            React.js
          </List.Item>
          <List.Item>
            Node.js (advantage)
          </List.Item>
          <List.Item>
            GraphQL (advantage)
          </List.Item>
          <List.Item>
            AWS (advantage)
          </List.Item>
        </List>

        <Header as='h3' style={{ fontSize: '1.1em' }}>
          How to apply?
        </Header>
        <p>
          Please, send us your resume.
          <br/>
          <br/>
          <Button color='teal'>
            <a style={{ color: 'white' }} href="mailto:admin@vuics.com?subject=Vuics Integrator Job&body=Hi Vuics Team, I want to apply on Vuics Integrator Job. <Attach resume>. Thanks.">
              Apply to the Job
            </a>
          </Button>
        </p>
      </Segment>
    </Container>
  )
}

export default Jobs
